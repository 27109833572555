<template>
    <span class="app-timer">
        <span class="app-timer--minutes">{{minutes_left}}</span>
        <span class="app-timer--divider">{{divider}}</span>
        <span class="app-timer--seconds">{{seconds_left}}</span>
    </span>
</template>

<script>
let intervalTimer = null

export default {
    props: {
        seconds: { Type: Number, default: 60    }, // max 3600 = 1 hour
        divider: { Type: String, default: ' : ' },
    },

    data() {
        return {
            minutes_left: '--',
            seconds_left: '--',
        }
    },

    created() {
        this.setTime(this.seconds)
    },

    beforeDestroy() {
        this.clearTimer()
    },

    methods: {
        clearTimer() {
            if (intervalTimer) {
                clearInterval(intervalTimer)
                intervalTimer = null
            }
        },

        setTime(seconds) {
            if (isNaN(seconds) || seconds <= 0 || seconds > 3600) {
                this.$emit('timerCompleted')
                return
            }

            this.clearTimer()
            this.timer(seconds)
        },

        timer(seconds) {
            const now = Date.now()
            const end = now + seconds * 1000

            this.displayTimeLeft(seconds)

            this.countdown(end)
        },

        countdown(end) {
            intervalTimer = setInterval(() => {
                const secondsLeft = Math.round((end - Date.now()) / 1000)

                if (secondsLeft < 0) {
                    this.clearTimer()
                    this.$emit('timerCompleted')
                    return
                }

                this.displayTimeLeft(secondsLeft)
            }, 1000)
        },

        displayTimeLeft(secondsLeft) {
            this.$emit('secondsLeft', secondsLeft)

            if (secondsLeft == 3600) {
                this.minutes_left = '--'
                this.seconds_left = '--'

                return
            }

            const minutes = Math.floor((secondsLeft % 3600) / 60)
            const seconds = secondsLeft % 60

            this.minutes_left = this.zeroPadded(minutes)
            this.seconds_left = this.zeroPadded(seconds)
        },

        zeroPadded(num) {
            return num < 10 ? `0${num}` : num
        },
    }
}
</script>

<style lang="scss">
.app-timer {
    display: flex;
    align-items: center;

    &--minutes, &--seconds {
        font-size: 18px;
        line-height: 1.33;
        color: $text-color-header;
    }

    &--divider {
        margin-bottom: 2px;

        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        white-space: pre;
        color: rgba($gray, 0.4);
    }
}
</style>
