<template>
  <div>
    <canvas ref="canvas" :height="height" :width="width"></canvas>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Rive',

    props: {
        src:           { type: String,  default: '/assets/animations/sign_in.riv'  },
        autoplay:      { type: Boolean, default: true    },
        stateMachines: { type: String                    },
        fit:           { type: String, default: 'cover'  }, // contain, cover, fill, fitHeight, fitWidth, none, scaleDown
        alignment:     { type: String, default: 'center' }, // center, bottomCenter, bottomLeft, bottomRight, centerLeft, centerRight, topCenter, topLeft, topRight
        height:        { type: [ String, Number ], default: '400' },
        width:         { type: [ String, Number ], default: '400' },
    },

    data() {
        return {
            riveinstance: null,
            layout: null,
        }
    },

    mounted() {
        import(/* webpackChunkName: "Rive" */ '@rive-app/canvas').then(({ Rive, Layout }) => {
            this.layout = Layout

            this.riveinstance = new Rive({
                canvas: this.$refs.canvas,
                src: this.src,
                layout: new Layout({
                    fit: this.fit,
                    alignment: this.alignment,
                    maxX: this.width,
                    maxY: this.height,
                }),

                /* onLoad: () => {  
                    console.log(this.riveinstance.contents)
                }, */
                
                stateMachines: this.stateMachines,
                autoplay: this.autoplay,
                onLoadError: () => { this.$emit('error') },
                onStop: () => { this.onStop() },
            });
        })
    },

    watch: {
        height_and_width: {
            immediate: false,
            handler() {
                if (this.riveinstance && this.layout) {
                    this.riveinstance.layout = new this.layout({
                        maxX: this.width,
                        maxY: this.height,
                    })
                }
            }
        },

        play_animation: {
            immediate: true,
            handler(play_animation) {
                if (play_animation.name) {
                    this.play(play_animation.name)
                }
            }
        },
    },

    computed: {
        ...mapGetters(['play_animation']),

        height_and_width() {
            return `${this.height}_${this.width}`
        },
    },

    methods: {
        play(animation_name) {
            if (this.riveinstance) {
                this.riveinstance.play(animation_name)
            }
        },

        onStop() {
            this.$emit('stop')

            this.$store.dispatch('StopAnimation', { name: this.play_animation.name })
        }
    },
};
</script>
