// checkWebPFeature:
// 'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
// https://developers.google.com/speed/webp/faq
const checkWebPFeature = (feature) => new Promise(resolve => {
    const kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
    };

    const img = new Image();

    img.onload = function () {
        const result = (img.width > 0) && (img.height > 0);
        resolve(result)
    };

    img.onerror = function () {
        resolve(false)
    };
    
    img.src = "data:image/webp;base64," + kTestImages[feature];
})

const MAX_MEDIA_COUNT = 3;

export default {
  state: {
    images: {},
  },

  getters: {
    theme_images_object: (state) => state.images,

    getImage: (state) => (name) => state.images[name] && state.images[name].src,

    getImageObj: (state) => (name) => state.images[name],

    getImageObjArrByMedia: (state) => (name) => {
      const imageObjArrByMedia = [];

      for (let i = 0; i < MAX_MEDIA_COUNT; i++) {
        const image_name = name + (i ? `__${i}` : '');
        const image = state.images[image_name];

        if (image) {
          imageObjArrByMedia[i] = image;
        }
      }

      return imageObjArrByMedia;
    },

    getImageCssVars: (state, getters) => (css_var, name, is_inherit = false, ratio_fallback) => {
      const imageCssVars = {};

      const imageObjArr = getters.getImageObjArrByMedia(name);
      let image_url = is_inherit ? null : 'none';
      let image_ratio = is_inherit ? null : ratio_fallback;

      for (let i = 0; i < MAX_MEDIA_COUNT; i++) {
        const image = imageObjArr[i];

        if (image) {
          image_url = `url(${image.src})`;
          image_ratio = image.ratio;
        }

        if (image_url) {
          imageCssVars[`${css_var}__${i}`] = image_url;
        }

        if (image_ratio) {
          imageCssVars[`${css_var}-ratio__${i}`] = image_ratio;
        }
      }

      return imageCssVars;
    },
  },

  actions: {
    async scanImages({ commit }) {
      const isWebPSupported = await checkWebPFeature('lossy');

      const getPics = isWebPSupported 
        ? require.context('@theme/img', false, /.*\.(svg|webp)$/) 
        : require.context('@theme/img', false, /.*\.(svg|png)$/);

      const cutExtOff = (filename) => filename.split(/\.\/|\./).reverse()[1].split('[')[0];

      const pics_filenames = getPics.keys();

      const pics_names = pics_filenames.map(cutExtOff);

      const pics_images = pics_filenames.map(getPics);

      const THEME = process.env.VUE_APP_THEME

      pics_names.forEach((name, index) => {
        const full_name = pics_filenames[index]
        const full_name_sliced = full_name.slice(2)

        let file_type = 'unknown'
        const las_index_of_ext_separator = full_name_sliced.lastIndexOf('.')

        if (full_name_sliced && las_index_of_ext_separator > -1 && las_index_of_ext_separator + 1 < full_name_sliced.length) {
            file_type = full_name_sliced.slice(las_index_of_ext_separator + 1)
        }

        const image = {
          name,
          file_type,
          full_name: full_name_sliced,
          initial_path_to_file: `src/assets/themes/${THEME}/img/${full_name_sliced}`,
          src: pics_images[index],
        };

        const width_height_from_src = pics_filenames[index].match(/\[(\d+)x(\d+)]/);

        if (width_height_from_src) {
            const [ , width, height] = width_height_from_src;

            image.width = +width;
            image.height = +height;
            image.ratio = width / height;

            commit('ADD_IMAGE', image);

        } else {
            const img = new Image();

            img.onload = function() {
                image.width = this.width;
                image.height = this.height;
                image.ratio = this.width / this.height;

                commit('ADD_IMAGE', image);
            };
            
            img.src = image.src;
        }
      });
    },
  },

  mutations: {
    ADD_IMAGE(state, image) {
      state.images = { ...state.images, [image.name]: image };
    },
  },
};
