const plans_by_types = {
    postpay: {
        uuid: 1,
        name: 'postpay',
        title: 'Postpay',
        products: [
            {
                uuid: 1,
                title: 'Plan Title',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
                props: [
                    {desc: 'sed ut perspiciatis', value: 50, measure: 'GB'},
                    {desc: 'iste natus erro', value: 10, measure: 'GB'},
                    {desc: 'eaque ipsa quae', value: 100, measure: 'Mbps'},
                ],
                price: 75,
                currency: '$',
                per: 'month'
            },
            {
                uuid: 1,
                title: 'Plan Title',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
                props: [
                    {desc: 'sed ut perspiciatis', value: 50, measure: 'GB'},
                    {desc: 'iste natus erro', value: 10, measure: 'GB'},
                    {desc: 'eaque ipsa quae', value: 100, measure: 'Mbps'},
                ],
                price: 75,
                currency: '$',
                per: 'month'
            },
            {
                uuid: 1,
                title: 'Plan Title',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
                props: [
                    {desc: 'sed ut perspiciatis', value: 50, measure: 'GB'},
                    {desc: 'iste natus erro', value: 10, measure: 'GB'},
                    {desc: 'eaque ipsa quae', value: 100, measure: 'Mbps'},
                ],
                price: 75,
                currency: '$',
                per: 'month'
            },
        ],
    },
    prepay: {
        uuid: 1,
        name: 'prepay',
        title: 'Prepay',
        products: [
            {
                uuid: 1,
                title: 'Plan Title Prepay 1',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
                props: [
                    {desc: 'sed ut perspiciatis', value: 50, measure: 'GB'},
                    {desc: 'iste natus erro', value: 10, measure: 'GB'},
                    {desc: 'eaque ipsa quae', value: 100, measure: 'Mbps'},
                ],
                price: 75,
                currency: '$',
                per: 'month'
            },
            {
                uuid: 2,
                title: 'Plan Title Prepay 2',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                props: [
                    {desc: 'sed ut perspiciatis', value: 50, measure: 'GB'},
                    {desc: 'iste natus erro', value: 10, measure: 'GB'},
                    {desc: 'eaque ipsa quae', value: 100, measure: 'Mbps'},
                ],
                price: 75,
                currency: '$',
                per: 'month'
            },
            {
                uuid: 3,
                title: 'Plan Title  Prepay 3 Long Long Long',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
                props: [
                    {desc: 'sed ut perspiciatis', value: 50, measure: 'GB'},
                    {desc: 'iste natus erro', value: 10, measure: 'GB'},
                    {desc: 'eaque ipsa quae', value: 100, measure: 'Mbps'},
                ],
                price: 75,
                currency: '$',
                per: 'month'
            },
        ],
    },
}

export default plans_by_types
