import store from '@/store';

export default (to, from, next) => {
    const is_invoices_allowed = store.getters.is_app_environment_invoices_allowed;

    return {
        allow: is_invoices_allowed,
        redirect: { name: 'not-found' },
    }
};
