// import { firestoreAction } from 'vuexfire';

import { db } from '@/utils/firebase.js';

import i18n from '@/i18n';

import dynStorage from '@/utils/storage-dynamic';

// TODO get INTERNATIONAL_PLANS from Firestore when endpoint is ready
import INTERNATIONAL_PLANS from '@/views/international-plans/international_plans';
import INTERNATIONAL_FLAGS from '@/assets/img/flags';

export default {
  state: {
    // TODO get international_plans from Firestore when endpoint is ready
    international_plans: INTERNATIONAL_PLANS,
    international_flags: INTERNATIONAL_FLAGS,
  },

  getters: {
	calculate_services: (state) => (services) => {
      return services
        .filter((service) => service.options && service.options.length)
        .map(({ options }) => options[0])
        .reduce((acc, { title, units, value }) => {
          acc[title] = `${value || ''} ${units}`;

          if (units.toLowerCase().includes('unlimited')) {
            acc[title] = { value: acc[title], info: i18n.t('fair_use_policy_applies') };
          }
          return acc;
        }, {});
    }, 
    
    current_product_usage_rules: (state, getters) => {
        const usage = getters.customer_current_product_usage

        const data_usage = usage.find(usage => usage.UsageType == 'data')
        const voice_usage = usage.find(usage => usage.UsageType == 'voice')
        const sms_usage = usage.find(usage => usage.UsageType == 'sms')
        const mms_usage = usage.find(usage => usage.UsageType == 'mms')

        const idd_voice_usage = usage.find(usage => usage.UsageType == 'idd_voice')
        const idd_sms_usage = usage.find(usage => usage.UsageType == 'idd_sms')

        return {
            data: data_usage?.UsageRule,
            voice: voice_usage?.UsageRule,
            sms: sms_usage?.UsageRule,
            mms: mms_usage?.UsageRule,
            idd_voice: idd_voice_usage?.UsageRule,
            idd_sms: idd_sms_usage?.UsageRule,
        }
    },
    
    international_plans: state =>  state.international_plans,
    international_flags: state =>  state.international_flags,

    is_show_plans: (state, getters) => getters.getAppEnvParam('IS_SHOW_PLANS') == 'true' || (getters.getAppEnvParam('IS_SHOW_PLANS') == 'local' && dynStorage.get('plans') == 'true'),
  },

  actions: {
	async checkingExistingMobileService({}, customerUuid) {
		const customer = await db.doc(`customers/${customerUuid}`).get();
		const prePayProducts = await customer.ref.collection('prepay_products').get();
		const productsDoc = prePayProducts.docs;
	  
		for (let doc of productsDoc) {
		  return doc.data();
		}
	  
		throw 'ANY_SERVICE';
	},

	async checkingExistingAddons({}, customerUuid) {
		const customer = await db.doc(`customers/${customerUuid}`).get();
		const prePayProducts = await customer.ref.collection('prepay_products').get();
		const productsDoc = prePayProducts.docs;
	  
		for (let doc of productsDoc) {
		  return doc.data();
		}
	},
  },

  mutations: {},
};
