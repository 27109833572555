import errMessage from '@/helpers/err-message'

let showSuccessMessageResolve = null

export default {
    state: {
        success_modal: {
            show: false,
            message: null,
            has_header: true,
            title: undefined,
            button_caption: undefined,
        },
    },
    
    getters: {
        success_modal_show: state => state.success_modal.show,
        success_modal_message: state => state.success_modal.message,
        success_modal_has_header: state => state.success_modal.has_header,
        success_modal_title: state => state.success_modal.title,
        success_modal_button_caption: state => state.success_modal.button_caption,
    },

    mutations: {
        showSuccessMessage(state, { success_message, title, button_caption, has_header = true }) {
            state.success_modal = {
                has_header,
                title,
                button_caption,
                show: true,
                message: success_message,
            }
        },

        hideSuccess(state) {
            state.success_modal = {
                show: false,
                message: null,
                has_header: true,
                title: undefined,
                button_caption: undefined,
            }

            if (showSuccessMessageResolve) {
                showSuccessMessageResolve(true)
                showSuccessMessageResolve = null
            }
        },
    },

    actions: {
        showSuccess({ commit }, success) {
            commit('showSuccessMessage', { success_message: errMessage(success) })
        },

        showSuccessMessage({ commit }, { success_message, title, button_caption, has_header = true } = {}) {
            commit('showSuccessMessage', { success_message, title, button_caption, has_header })
        },

        showSuccessMessagePromise({ dispatch }, { success_message, title, button_caption, has_header = true } = {}) {
            return dispatch('showSuccessMessage', { success_message, title, button_caption, has_header }).then(() => new Promise(resolve => { 
                showSuccessMessageResolve = resolve
            }))
        },

        hideSuccess({ commit }) {
            commit('hideSuccess')
        },
    },
}