import store from '@/store';

export default (to, from, next) => {
    const is_sim_swap_allowed = store.getters.is_sim_swap_allowed;

    return {
        allow: is_sim_swap_allowed,
        redirect: { name: 'not-found' },
    }
};
