import axios_zendesk from '@/utils/axios-zendesk.js'
import axiosIntents from '@/utils/axios-intents'

import StoreCache from '@/utils/StoreCache.js'

const ZE_CHAT_SNIPPET = process.env.VUE_APP_ZENDESK_CHAT_SNIPPET
const ZE_CHAT_KEY = process.env.VUE_APP_ZENDESK_CHAT_KEY

const DEFAULT_LIFETIME_USAGE = 1 * 60 * 60 * 1000 // 1 hour

export default {
    state: {
        categories_list: new StoreCache(DEFAULT_LIFETIME_USAGE),
        sections_list: new StoreCache(DEFAULT_LIFETIME_USAGE),
        articles_list: new StoreCache(DEFAULT_LIFETIME_USAGE),
        articles_search: {
            searches: {},
            lifetime: 10 * 60 * 1000, // 10 minutes
        },
    },

    getters: {},

    actions: {
        GetZendeskCategoriesList({ state, commit }) {
            const categories_list = state.categories_list

            if (!categories_list.expired()) {
                return Promise.resolve(categories_list.cachedData())
            }

            const request_url = '/api/v2/help_center/categories.json'

            return axios_zendesk.get(request_url).then(({data}) => {
                commit('SAVE_CATEGORIES_LIST', data.categories)
                return data.categories
            })
            .catch(error => {
                console.log({error});

                return Promise.resolve([])
            })
        },

        GetZendeskSectionsList({ state, commit }) {
            const sections_list = state.sections_list
            if (!sections_list.expired()) {
                return Promise.resolve(sections_list.cachedData())
            }

            const request_url = '/api/v2/help_center/sections.json'

            return axios_zendesk.get(request_url).then(({data}) => {
                commit('SAVE_SECTIONS_LIST', data.sections)
                return data.sections
            })
            .catch(error => {
                console.log({error});

                return Promise.resolve([])
            })
        },

        GetZendeskArticlesList({ state, commit }) {
            const articles_list = state.articles_list
            if (!articles_list.expired()) {
                return Promise.resolve(articles_list.cachedData())
            }

            const request_url = '/api/v2/help_center/articles.json?per_page=100'

            return axios_zendesk.get(request_url).then(({data}) => {
                // Only show non-draft articles

                //
                // TODO: Implement a reseller API that performs
                // filtering on the backend for:
                //
                // draft = false
                // user_segment_id = null / empty
                //
                let non_draft = []
                if (data.articles) {
                    non_draft = data.articles.filter( ({draft}) => !draft )
                }
                commit('SAVE_ARTICLES_LIST', non_draft)
                return non_draft
            })
            .catch(error => {
                console.log({error});

                return Promise.resolve([])
            })
        },

        FindZendeskArticles({ state, commit }, search_word) {
            const search_cache = state.articles_search.searches[search_word]
            if (search_cache && !search_cache.expired()) {
                return Promise.resolve(search_cache.cachedData())
            }

            const request_url = `/api/v2/help_center/articles/search?query=${search_word}`

            return axios_zendesk.get(request_url).then( ({data}) => {
                const result_ids = data.results.map( ({id}) => id )

                commit('SAVE_ARTICLES_SEARCH', {search_word, result_ids})

                return result_ids
            }).catch( error => {
                console.info('Search Articles Error', error)

                return Promise.resolve([])
            })
        },

        InitZendeskChat({ state, commit, dispatch, getters }, should_chat_show = false) {
            const id = 'ze-snippet'
            if (document.getElementById(id)) { return }

            const script = document.createElement('script')

            script.id = id
            script.async = true
            script.src = `${ZE_CHAT_SNIPPET}?key=${ZE_CHAT_KEY}`

            document.getElementsByTagName('body')[0].appendChild(script)

            script.onload = () => {
                if (window.zE) {
                    window.zE('messenger', 'close')
                }

                if (getters.is_account) {
                    dispatch('LogInZendeskChat', should_chat_show)
                } else {
                    commit('LOGOUT_ZENDESK_CHAT', should_chat_show)
                }
            };
        },

        LogInZendeskChat({ commit, getters }, should_chat_show = false) {
            if (!getters.is_account) return

            axiosIntents.post('/support/login/:uuid').then(({result}) => {
                if (!result) Promise.reject('No Zendesk Chat Token')

                commit('LOGIN_ZENDESK_CHAT', { should_chat_show, jwt: result.Token } )
            }).catch(error => {
                commit('LOGOUT_ZENDESK_CHAT', should_chat_show)

                console.log('ZendeskChat get Token Error', error)
            })
        },
    },

    mutations: {
        SAVE_CATEGORIES_LIST(state, categories_list) {
            state.categories_list.fix(categories_list)
        },

        DELETE_CATEGORIES(state) {
            state.categories_list.expireNow()
        },

        SAVE_SECTIONS_LIST(state, sections_list) {
            state.sections_list.fix(sections_list)
        },

        DELETE_SECTIONS(state) {
            state.sections_list.expireNow()
        },

        SAVE_ARTICLES_LIST(state, articles_list) {
            state.articles_list.fix(articles_list)
        },

        DELETE_ARTICLES(state) {
            state.articles_list.expireNow()
        },

        SAVE_ARTICLES_SEARCH(state, { search_word, result_ids }) {
            if (!state.articles_search.searches[search_word]) {
                state.articles_search.searches[search_word] = new StoreCache(state.articles_search.lifetime)
            }

            state.articles_search.searches[search_word].fix(result_ids)
        },

        DELETE_ARTICLES_SEARCH(state, search_word) {
            delete state.articles_search.searches[search_word]
        },

        LOGIN_ZENDESK_CHAT(state, { jwt, should_chat_show = false } = {}) {
            if (window.zE) {
                window.zE('messenger', 'loginUser', function (callback) {
                    callback(jwt)
                })

                if (should_chat_show) {
                    window.zE('messenger', 'show')
                }
            }
        },

        LOGOUT_ZENDESK_CHAT(state, should_chat_show = false) {
            if (window.zE) {
                window.zE('messenger', 'logoutUser')

                if (should_chat_show) {
                    window.zE('messenger', 'show')
                }
            }
        },
    }
}