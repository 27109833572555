const state = {}

const getters = {
    is_order_placement_allowed: (state, getters) => ['true', 'yes', 1].includes(getters.getAppEnvParam('IS_ORDER_PLACEMENT_ALLOWED').toLowerCase())
                                                    && (getters.is_show_ufb_module || getters.is_sim_purchasing_allowed),
}

const mutations = {}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}