import Vue from 'vue';
import { createPopper } from '@popperjs/core';
import { uuid } from '@/helpers/uuid';
import { checkOverflow } from '@/helpers/check-overflow';
import { isString, isObject } from '@/helpers/object-type';

/**
 * Directive usage
 * 1. value as String: v-tooltip="_tooltip_text_"
 * 2. value as Object: v-tooltip="{ text: _tooltip_text_, options: { placement: 'top' }}"
 * 
 * Directive arguments:
 * always (default) -- show tooltip on hover or click
 * overflow - show tooltip if element has overflow v-tooltip:overflow="_full_text_of_overflowed_element_"
 */

const poppers = new Map();

const defaults = {
    placement: 'bottom-end',
};

const showEvents = ['mouseenter', 'focus', 'click'];
const hideEvents = ['mouseleave', 'blur'];

const createTooltipElement = (text, mode) => {
    const id = `app-tooltip-${uuid()}`;
    const tooltipElement = document.createElement('div');

    tooltipElement.innerHTML = text;
    tooltipElement.setAttribute('id', id);
    tooltipElement.setAttribute('role', 'tooltip');
    tooltipElement.setAttribute('data-mode', mode);

    return tooltipElement;
};

const updateTooltipElement = (el, text) => {
    const { tooltipElement } = poppers.get(el);

    tooltipElement.innerHTML = text;
};

const createPopperInstance = (
    targetElement,
    tooltipElement,
    mode,
    popperOptions
) => {
    const popperInstance = createPopper(targetElement, tooltipElement, popperOptions);

    poppers.set(targetElement, { popperInstance, tooltipElement, mode });
};

const setPopperEventListeners = (popperInstance, enabled) => {
    popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [...options.modifiers, { name: 'eventListeners', enabled }],
    }));
};

const show = (evt) => {
    const el = evt.target;
    const poppers_el = poppers.get(el);

    if (!poppers_el) return;

    const { popperInstance, tooltipElement, mode } = poppers.get(el);

    // do not show empty tooltip
    if (tooltipElement.innerHTML.trim() === '') return;

    if (mode === 'overflow' && !checkOverflow(el)) return;

    tooltipElement.setAttribute('data-show', '');
    setPopperEventListeners(popperInstance, true);

    popperInstance.update();
};

const hide = (evt) => {
    const el = evt.target;
    const poppers_el = poppers.get(el);

    if (!poppers_el) return;

    const { popperInstance, tooltipElement } = poppers.get(el);

    tooltipElement.removeAttribute('data-show');
    setPopperEventListeners(popperInstance, false);
};

const startListening = (el) => {
    showEvents.forEach((event) => {
        el.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
        el.addEventListener(event, hide);
    });
};

const stopListening = (el) => {
    showEvents.forEach((event) => {
        el.removeEventListener(event, show);
    });

    hideEvents.forEach((event) => {
        el.removeEventListener(event, hide);
    });
};


Vue.directive('tooltip', {
    inserted(el, { value, arg: mode = 'always' }, vnode) {

        if (!isString(value) && (!isObject(value) || !isString(value.text))) {
            throw new Error(
                `[VTooltipDirective] Value must be a string or an object with string 'text' property; got ${typeof value}!`
            );
        }
    
        if (!['always', 'overflow'].includes(mode)) {
            throw new Error(`[VTooltipDirective] Only 'always' and 'overflow' arg are supported; got ${mode}!`);
        }
    
        let text, popperOptions;
    
        if (isString(value)) {
            text = value;
            popperOptions = defaults;
        } else {
            text = value.text;
            popperOptions = { ...defaults, ...value.options };
        }
    
        const tooltipElement = createTooltipElement(text, mode);
        el.appendChild(tooltipElement);
    
        createPopperInstance(el, tooltipElement, mode, popperOptions);
        startListening(el);
    },

    componentUpdated(el, { value }) {
        let text;

        if (isString(value)) {
            text = value;
        } else {
            text = value.text;
        }
    
        updateTooltipElement(el, text);
    },

    unbind (el) {
        poppers.delete(el);
        stopListening(el);
    },
});
