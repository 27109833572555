import axiosIntents from '@/utils/axios-intents'

import i18n from '@/i18n'

const state = {
    Stripe: null,
    stripe_initialized: null,
}

const getters = {
    client_secret: state => state.client_secret,
}

const mutations = {
    saveStripe(state, Stripe) {
        state.Stripe = Stripe
        state.stripe_initialized = null
    },
    stripeInitialized(state, promise) {
        state.stripe_initialized = promise
    },
}

const actions = {
    stripeInit({state, commit}, config) {
        if (state.stripe_initialized) {
            return state.stripe_initialized
        } else {
            if (state.Stripe) {
                return Promise.resolve(state.Stripe)
            } if ('Stripe' in window) {
                const Stripe = window.Stripe(
					process.env.VUE_APP_STRIPE_API_KEY, 
					{ locale: 'en' }
				);

                commit('saveStripe', Stripe)
                return Promise.resolve(state.Stripe)
            } else {
                let stripe_initialized = new Promise((resolve, reject) => {
                    let script = document.createElement('script')
                        script.src = 'https://js.stripe.com/v3/'

                        script.onreadystatechange = script.onload = () => {
                            if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                                const Stripe = window.Stripe(
									process.env.VUE_APP_STRIPE_API_KEY, 
									{ locale: 'en' }
								);

                                commit('saveStripe', Stripe)
                                resolve(state.Stripe)
                            }
                        }

                        script.onerror = () => {
                            reject(new Error(i18n.t('error_by_stripe_loading')))
                        }

                    document.getElementsByTagName('head')[0].appendChild(script)
                })

                commit('stripeInitialized', stripe_initialized)

                return stripe_initialized
            }
        }
    },

    stripePaymentConfirmation({dispatch}, { card_token, client_secret }) {
        return dispatch('stripeInit').then(stripe => {
            return stripe.confirmCardPayment(client_secret, card_token ? {
                payment_method: card_token
            } : {}).then(confirmation_result => {
                if (confirmation_result?.error) {
                    return Promise.reject(confirmation_result?.error)
                }

                return Promise.resolve(confirmation_result)
            })
        }).catch(error => {
            console.log('stripePaymentConfirmation error', error)
            
            return Promise.reject(error)
        })
    },
}

export default {
	state,
	getters,
	mutations,
	actions,
}