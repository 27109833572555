const prepareCard = (card) => ({
  ...card, displayedValue:'•••• ' + card.last4, PaymentMethodType: "cards", PaymentMethodID: card.cardId
})
const prepareRoute = (route) => ({
  is_default: false,
  cardId: route.UUID,
  brand: "billRoute",
  displayedValue: `${route.Name}: $${(route.RemainingSpendLimit / 100).toFixed(2)}`,
  PaymentMethodType: "bill_routes",
  PaymentMethodID: route.UUID,
  PaymentLimit: (route.RemainingSpendLimit / 100).toFixed(2)
})

const state = {};
const getters = {
  isShouldShowPaymentMethods: (state, getters) => {
    const paymentsCards = getters.payment_methods_cards
    const paymentsRoutes = getters.getParentBillingRoutes
    const isRoutingAllowed = getters.is_app_environment_billing_routing_allowed;
    if (isRoutingAllowed) return Boolean(paymentsCards?.length || (paymentsRoutes?.length && paymentsRoutes.some(route => route.RemainingSpendLimit > 0)))
    return Boolean(paymentsCards?.length)
  },
  
  getPaymentOptions: (state, getters) => {
    const paymentsCards = getters.payment_methods_cards.map(prepareCard)
    const paymentsRoutes = getters.getParentBillingRoutes.map(prepareRoute)
    const isRoutingAllowed = getters.is_app_environment_billing_routing_allowed;
    if (isRoutingAllowed) return [...paymentsCards, ...paymentsRoutes]
    return paymentsCards
  }
};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
}
