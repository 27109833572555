import i18n from '@/i18n';

const plan_types_list = [
    {
        name: 'postpay',
        title: i18n.t('plan_types_list.postpay.title'),
        dropdown_title: i18n.t('plan_types_list.postpay.dropdown_title'),
        lead: i18n.t('plan_types_list.postpay.lead'),
        features: [
            i18n.t('plan_types_list.postpay.features.great_data_packages'),
            i18n.t('plan_types_list.postpay.features.bring_existing_number'),
            i18n.t('plan_types_list.postpay.features.amazing_loyalty_deals'),
            i18n.t('plan_types_list.postpay.features.solid_network'),
        ]
    },
    {
        name: 'prepay',
        title: i18n.t('plan_types_list.prepay.title'),
        dropdown_title: i18n.t('plan_types_list.prepay.dropdown_title'),
        lead: i18n.t('plan_types_list.prepay.lead'),
        features: [
            i18n.t('plan_types_list.prepay.features.great_data_packages'),
            i18n.t('plan_types_list.prepay.features.bring_existing_number'),
            i18n.t('plan_types_list.prepay.features.amazing_loyalty_deals'),
            i18n.t('plan_types_list.prepay.features.solid_network'),
        ]
    },
    {
        name: 'PAYG',
        title: i18n.t('plan_types_list.payg.title'),
        dropdown_title: i18n.t('plan_types_list.payg.dropdown_title'),
        lead: i18n.t('plan_types_list.payg.lead'),
        features: [
            i18n.t('plan_types_list.payg.features.great_data_packages'),
            i18n.t('plan_types_list.payg.features.bring_existing_number'),
            i18n.t('plan_types_list.payg.features.amazing_loyalty_deals'),
            i18n.t('plan_types_list.payg.features.solid_network'),
        ]
    },
]

export default plan_types_list