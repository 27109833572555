import store from '@/store';

export default (to, from, next) => {
    const allow = !store.getters.is_account_type_activated || (store.getters.is_account_type_activated && (store.getters.customer_current_product_has_base_plan || store.getters.no_addons_notified))

    if (!allow) {
        return Promise.reject({
            allow,
            redirect: { name: 'no-addons-page' },
        })
    }

    return Promise.resolve({
        allow,
        redirect: { name: 'home' },
    })
};
