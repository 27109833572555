/**
 * Перехватчик запросов для axios:
 * - добавляет токен авторизации в заголовке Authorization при отправке каждого запроса
 * - обрабатывает полученнй результат по запросу
 * - разлогинивает юзера при получении 401
 *
 * @param {*} Vue
 * @param {*} axios
 * @param {*} store
 * @param {*} router
 */
const axios_config = {
  timeout: 65000,
  maxRedirects: 1,
  withCredentials: false,
};

const X_Auth_Key = 'x-auth-token';

let is_processing_auth_check = false;

function axiosMiddleware(Vue, axios, store, router) {
  axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

  // добавляем токен авторизации
  // add auth token
  axios.interceptors.request.use(
    (config) => {
      const app_spid = store.getters.app_spid;

      if (app_spid) {
        config.url = config.url.replace(/:spid(\/|\?|$)/gi, `${app_spid}$1`);
      }

      if (!config.is_auth_check && !config.headers.hasOwnProperty(X_Auth_Key) && !config.params?.noauth) {
        let auth_token = store.getters.jwt;
        const auth_token_is_valid = store.getters.jwt_is_valid();

        if (auth_token && auth_token_is_valid) {
          config.headers[X_Auth_Key] = auth_token;
        } else if (is_processing_auth_check) {
          return new Promise((resolve, reject) => {
            const unwatch = store.watch((state, getters) => getters.is_processing_auth_check, is_processing_auth_check => {
              if (!is_processing_auth_check) {
                unwatch()

                auth_token = store.getters.jwt;

                if (!auth_token) {
                  reject('No auth token');
                } else {
                  config.headers[X_Auth_Key] = auth_token;

                  resolve({ ...axios_config, ...config });
                }
              }
            })
          })
        } else {
          is_processing_auth_check = true;

          return store.dispatch('AuthCheckViaFirebaseToken')
          .then(() => {
            auth_token = store.getters.jwt;

            if (!auth_token) return Promise.reject('No auth token');

            config.headers[X_Auth_Key] = auth_token;

            return Promise.resolve({ ...axios_config, ...config });
          })
          .catch(error => {
            return Promise.reject(error)
          })
          .finally(() => {
            is_processing_auth_check = false;
          });
        }
      }

      return { ...axios_config, ...config };
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    // обрабатываем результат
    // process the result
    (response) => {
      response.result = response.data;
      response.apidata = response.data;
      if (response.headers && response.headers['x-auth-token'] && response.headers['x-auth-token-expiry']) {
        // If API returns a new X-Auth-Token, then we must replace our existing one
        // console.log('got new jwt:', response.headers['x-auth-token'])
        store.dispatch('setJWT', response.headers['x-auth-token'], response.headers['x-auth-token-expiry']);
      }

      return Promise.resolve(response);
    },

    // разлогиниваем юзера
    // log off user
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          // 401: Unauthorized
          case 401:
            {
              if (error.response.data.err_number === 1002) {
                /**
                 * err_number 1002
                 * err_message Missing authentication role
                 */

                /**
                 * Иногда API возвращает 401 статус-код (Неавторизовано), вместо 403 (Запрещено)
                 * Например, если у пользователя недостаточно прав...
                 * Так как проблема не в сроке жизни токена, а в правах...
                 * Мы попадаем в бесконечный цикл: успешное обновление токена => запрос с 401, успешное обновление токена => запрос с 401, ...
                 */

                return Promise.reject(error);
              } else {
                return store.dispatch('FirebaseLogout').then(() => {
                  return Promise.reject(error);
                });
              }
            }
            break;

          // 403: Forbidden
          case 403:
            {
              // TODO on dev
              // router.push({ name: 'sign-in' });
              return Promise.reject(error);
            }
            break;

          default: {
            return Promise.reject(error);
          }
        }
      } else {
        return Promise.reject(error);
      }
    },
  );
}

export default axiosMiddleware;
