const SIGN_IN_ROUTE_NAME = 'sign-in'

export default (store) => [
    {
        path: '/link',
        children: [
            { 
                path: 'home',
                redirect: () =>  {
                    const redirect = 'profile-plans-dashboard'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'products',
                redirect: () =>  {
                    const redirect = 'profile-plans-addons'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'invoices',
                redirect: () =>  {
                    const redirect = 'profile-account-invoices'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'payment-methods',
                redirect: () =>  {
                    const redirect = 'profile-balance-payment-methods'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'usage',
                redirect: () =>  {
                    const redirect = 'profile-plans-usage'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'mobile-features',
                redirect: () =>  {
                    const redirect = 'profile-plans-sim-card-actions'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'sim-settings',
                redirect: () =>  {
                    const redirect = 'profile-plans-supp-features'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'payments-history',
                redirect: () =>  {
                    const redirect = 'profile-balance-payments-history'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'shipping-details',
                redirect: () =>  {
                    const redirect = 'profile-shipping-details'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'order-history',
                redirect: () =>  {
                    const redirect = 'profile-order-history'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'notifications',
                redirect: () =>  {
                    const redirect = 'change-notifications'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'voucher',
                redirect: () =>  {
                    const redirect = 'profile-redeem-voucher'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'full-amount',
                redirect: () =>  {
                    const redirect = 'profile-account-pay-bill'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect },
                    }
                },
            },
            { 
                path: 'custom-amount',
                redirect: () =>  {
                    const redirect = 'profile-account-pay-bill'
                    const param = 'custom-amount'
                    const is_auth = store.getters.is_auth

                    return {
                        name: is_auth ? redirect : SIGN_IN_ROUTE_NAME,
                        query: is_auth ? {} : { redirect, param },
                        params: is_auth ? { param } : {},
                    }
                },
            },
            { path: 'chat',           redirect: { name: 'home', params: { chat: true } }},
            { path: 'help',           redirect: { name: 'help-center'                  }},
            { path: 'terms',          redirect: { name: 'terms'                        }},
            { path: 'privacy',        redirect: { name: 'privacy'                      }},
            { path: 'calling-rates',  redirect: { name: 'international-plans'          }},
        ],
    },
]
