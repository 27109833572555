import Storage from './StorageClass';

//
// This tests localStorage, then cookies to see which is enabled/working, and
// then uses sessionStorage as a fallback if these do not work
//
// This is to get around localStorage being disabled by some browser extensions
//

let thisStorage = null
let randomString = Math.random().toString(36).substring(7);

// First, lets see if local storage is enabled...
if (!thisStorage) {
    let localStorageInstance = new Storage('localStorage')
    localStorageInstance.set('test-local', randomString)
    const getLocalTest = localStorageInstance.get('test-local')
    localStorageInstance.remove('test-local')
    if (getLocalTest == randomString) {
        thisStorage = localStorageInstance
    }
}

// Second, lets see if cookies are enabled...
if (!thisStorage) {
    let cookiesStorageInstance = new Storage('cookies')
    cookiesStorageInstance.set('test-cookies', randomString)
    const getCookiesTest = cookiesStorageInstance.get('test-cookies')
    cookiesStorageInstance.remove('test-cookies')
    if (getCookiesTest == randomString) {
        thisStorage = cookiesStorageInstance
    }
}

// Fallback to session storage
if (!thisStorage) {
    thisStorage = new Storage('sessionStorage')
}

export default thisStorage
