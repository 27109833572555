import axios from 'axios';
import axiosIntents from '@/utils/axios-intents'

import i18n from '@/i18n'

import { MEGA_BASE_2 } from '@/consts'

const API_V2 = 'v2';

import formatDate from '@/helpers/date-helpers/format-date'
import StoreCache from '@/utils/StoreCache.js'

const DEFAULT_LIFETIME_USAGE = 1 * 60 * 60 * 1000 // 1 hour

const USAGE_TYPES = {
    'Data MB': { title: i18n.t('data'),  icon: 'data-usage',  type: 'Data MB',  is_integer: false,  unit: i18n.t('unit.MB'), usage_key: 'data'    },
    Voice:     { title: i18n.t('voice'), icon: 'phone-usage', type: 'Voice',    is_integer: true,   unit: i18n.t('unit.mins'), usage_key: 'voice' },
    SMS:       { title: i18n.t('SMS'),   icon: 'sms-usage',   type: 'SMS',      is_integer: true,   unit: i18n.t('unit.SMS'), usage_key: 'sms'    },
    MMS:       { title: i18n.t('MMS'),   icon: 'mms-usage',   type: 'MMS',      is_integer: true,   unit: i18n.t('unit.MMS'), usage_key: 'mms'    },
};

const USAGE_GROUPS = [
    { title: i18n.t('daily_upper'),   icon: 'calendar', group_key: 'day'   },
    { title: i18n.t('monthly_upper'), icon: 'calendar', group_key: 'month' },
    { title: i18n.t('yearly_upper'),  icon: 'calendar', group_key: 'year'  },
];

const USAGES = {
	'Data MB': {
	    history: {},
	    id: 'Data MB',
	    credit: 'CreditDataMB',
	    icon: 'data-usage',
	    total: 'TotalDataMB',
	    title: i18n.t('data'),
	    type: 'Data MB',
	    unit: i18n.t('unit.MB'),
	    kind: 'data',
	    is_integer: false,
	},
	/* Voice: {
	    history: {},
	    id: 'Voice',
	    credit: 'CreditVoiceMinutes',
	    icon: 'phone-usage',
	    total: 'TotalVoiceMinutes',
	    title: i18n.t('voice'),
	    type: 'Voice',
	    unit: i18n.t('unit.mins'),
	    kind: 'mins',
	    is_integer: true,
	},
	SMS: {
	    history: {},
	    id: 'SMS',
	    credit: 'CreditSMS',
	    icon: 'sms-usage',
	    total: 'TotalSMS',
	    title: i18n.t('SMS'),
	    type: 'SMS',
	    unit: i18n.t('unit.SMS'),
	    kind: 'txts',
	    is_integer: true,
	},
	MMS: {
	    history: {},
	    id: 'MMS',
	    credit: 'CreditMMS',
	    icon: 'mms-usage',
	    total: 'TotalMMS',
	    title: i18n.t('MMS'),
	    type: 'MMS',
	    unit: i18n.t('unit.MMS'),
	    kind: 'pxts',
	    is_integer: true,
	}, */
};

const DEFAULT_BUCKET = Array.from({ length: 30 }).fill(0);

const state = {
    usage: {},
    is_getting_usage: false,

    usage_charges: new StoreCache(DEFAULT_LIFETIME_USAGE),
    is_getting_usage_charges: false,
};

const getters = {
    usage_types: (state) => USAGE_TYPES,
    usage_groups: (state) => USAGE_GROUPS,
    is_getting_usage: (state) => state.is_getting_usage,
    is_getting_usage_charges: (state) => state.is_getting_usage_charges,
    usage: (state) => state.usage,

    usage_charges: (state) => state.usage_charges.cachedData() || [],

    has_usage_charges: (state, getters) => Boolean(getters.usage_charges.length),

    usage_data_charges: (state, getters) => getters.usage_charges.filter(usage_charge => usage_charge.ChargedData),
    usage_data_charges_by_year: (state, getters) => getters.usage_data_charges.reduce((acc, curr) => {
        const acc_usage_year = acc.find(usage => usage.year == curr.year)

        if (acc_usage_year) {
            acc_usage_year.TotalAmountCents = acc_usage_year.TotalAmountCents + curr.AmountCents
            acc_usage_year.TotalChargedUsage = acc_usage_year.TotalChargedUsage + curr.ChargedData
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedData,
                year: curr.year,
                title: curr.year,
                unit: 'MB',
            })
        }

        return acc
    }, []),
    usage_data_charges_by_month: (state, getters) => getters.usage_data_charges.reduce((acc, curr) => {
        const acc_usage_month = acc.find(usage => usage.year == curr.year && usage.month == curr.month)

        if (acc_usage_month) {
            acc_usage_month.TotalAmountCents = acc_usage_month.TotalAmountCents + curr.AmountCents
            acc_usage_month.TotalChargedUsage = acc_usage_month.TotalChargedUsage + curr.ChargedData
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedData,
                year: curr.year,
                month: curr.month,
                title: `${curr.month} ${curr.year}`,
                unit: 'MB',
            })
        }

        return acc
    }, []),
    usage_data_charges_by_day: (state, getters) => getters.usage_data_charges.reduce((acc, curr) => {
        const acc_usage_day = acc.find(usage => usage.year == curr.year && usage.month == curr.month && usage.day == curr.day)

        if (acc_usage_day) {
            acc_usage_day.TotalAmountCents = acc_usage_day.TotalAmountCents + curr.AmountCents
            acc_usage_day.TotalChargedUsage = acc_usage_day.TotalChargedUsage + curr.ChargedData
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedData,
                year: curr.year,
                month: curr.month,
                day: curr.day,
                title: `${curr.week_name} ${curr.day} ${curr.month} ${curr.year}`,
                unit: 'MB',
            })
        }

        return acc
    }, []),

    usage_voice_charges: (state, getters) => getters.usage_charges.filter(usage_charge => usage_charge.ChargedMins),
    usage_voice_charges_by_year: (state, getters) => getters.usage_voice_charges.reduce((acc, curr) => {
        const acc_usage_year = acc.find(usage => usage.year == curr.year)

        if (acc_usage_year) {
            acc_usage_year.TotalAmountCents = acc_usage_year.TotalAmountCents + curr.AmountCents
            acc_usage_year.TotalChargedUsage = acc_usage_year.TotalChargedUsage + curr.ChargedMins
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedMins,
                year: curr.year,
                title: curr.year,
                unit: 'minutes',
            })
        }

        return acc
    }, []),
    usage_voice_charges_by_month: (state, getters) => getters.usage_voice_charges.reduce((acc, curr) => {
        const acc_usage_month = acc.find(usage => usage.year == curr.year && usage.month == curr.month)

        if (acc_usage_month) {
            acc_usage_month.TotalAmountCents = acc_usage_month.TotalAmountCents + curr.AmountCents
            acc_usage_month.TotalChargedUsage = acc_usage_month.TotalChargedUsage + curr.ChargedMins
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedMins,
                year: curr.year,
                month: curr.month,
                title: `${curr.month} ${curr.year}`,
                unit: 'minutes',
            })
        }

        return acc
    }, []),
    usage_voice_charges_by_day: (state, getters) => getters.usage_voice_charges.reduce((acc, curr) => {
        const acc_usage_day = acc.find(usage => usage.year == curr.year && usage.month == curr.month && usage.day == curr.day)

        if (acc_usage_day) {
            acc_usage_day.TotalAmountCents = acc_usage_day.TotalAmountCents + curr.AmountCents
            acc_usage_day.TotalChargedUsage = acc_usage_day.TotalChargedUsage + curr.ChargedMins
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedMins,
                year: curr.year,
                month: curr.month,
                day: curr.day,
                title: `${curr.week_name} ${curr.day} ${curr.month} ${curr.year}`,
                unit: 'minutes',
            })
        }

        return acc
    }, []),

    usage_sms_charges: (state, getters) => getters.usage_charges.filter(usage_charge => usage_charge.ChargedTXTs),
    usage_sms_charges_by_year: (state, getters) => getters.usage_sms_charges.reduce((acc, curr) => {
        const acc_usage_year = acc.find(usage => usage.year == curr.year)

        if (acc_usage_year) {
            acc_usage_year.TotalAmountCents = acc_usage_year.TotalAmountCents + curr.AmountCents
            acc_usage_year.TotalChargedUsage = acc_usage_year.TotalChargedUsage + curr.ChargedTXTs
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedTXTs,
                year: curr.year,
                title: curr.year,
                unit: 'SMS',
            })
        }

        return acc
    }, []),
    usage_sms_charges_by_month: (state, getters) => getters.usage_sms_charges.reduce((acc, curr) => {
        const acc_usage_month = acc.find(usage => usage.year == curr.year && usage.month == curr.month)

        if (acc_usage_month) {
            acc_usage_month.TotalAmountCents = acc_usage_month.TotalAmountCents + curr.AmountCents
            acc_usage_month.TotalChargedUsage = acc_usage_month.TotalChargedUsage + curr.ChargedTXTs
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedTXTs,
                year: curr.year,
                month: curr.month,
                title: `${curr.month} ${curr.year}`,
                unit: 'SMS',
            })
        }

        return acc
    }, []),
    usage_sms_charges_by_day: (state, getters) => getters.usage_sms_charges.reduce((acc, curr) => {
        const acc_usage_day = acc.find(usage => usage.year == curr.year && usage.month == curr.month && usage.day == curr.day)

        if (acc_usage_day) {
            acc_usage_day.TotalAmountCents = acc_usage_day.TotalAmountCents + curr.AmountCents
            acc_usage_day.TotalChargedUsage = acc_usage_day.TotalChargedUsage + curr.ChargedTXTs
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedTXTs,
                year: curr.year,
                month: curr.month,
                day: curr.day,
                title: `${curr.week_name} ${curr.day} ${curr.month} ${curr.year}`,
                unit: 'SMS',
            })
        }

        return acc
    }, []),

    usage_mms_charges: (state, getters) => getters.usage_charges.filter(usage_charge => usage_charge.ChargedPXTs),
    usage_mms_charges_by_year: (state, getters) => getters.usage_mms_charges.reduce((acc, curr) => {
        const acc_usage_year = acc.find(usage => usage.year == curr.year)

        if (acc_usage_year) {
            acc_usage_year.TotalAmountCents = acc_usage_year.TotalAmountCents + curr.AmountCents
            acc_usage_year.TotalChargedUsage = acc_usage_year.TotalChargedUsage + curr.ChargedPXTs
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedPXTs,
                year: curr.year,
                title: curr.year,
                unit: 'MMS',
            })
        }

        return acc
    }, []),
    usage_mms_charges_by_month: (state, getters) => getters.usage_mms_charges.reduce((acc, curr) => {
        const acc_usage_month = acc.find(usage => usage.year == curr.year && usage.month == curr.month)

        if (acc_usage_month) {
            acc_usage_month.TotalAmountCents = acc_usage_month.TotalAmountCents + curr.AmountCents
            acc_usage_month.TotalChargedUsage = acc_usage_month.TotalChargedUsage + curr.ChargedPXTs
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedPXTs,
                year: curr.year,
                month: curr.month,
                title: `${curr.month} ${curr.year}`,
                unit: 'MMS',
            })
        }

        return acc
    }, []),
    usage_mms_charges_by_day: (state, getters) => getters.usage_mms_charges.reduce((acc, curr) => {
        const acc_usage_day = acc.find(usage => usage.year == curr.year && usage.month == curr.month && usage.day == curr.day)

        if (acc_usage_day) {
            acc_usage_day.TotalAmountCents = acc_usage_day.TotalAmountCents + curr.AmountCents
            acc_usage_day.TotalChargedUsage = acc_usage_day.TotalChargedUsage + curr.ChargedPXTs
        } else {
            acc.push({
                TotalAmountCents: curr.AmountCents,
                TotalChargedUsage: curr.ChargedPXTs,
                year: curr.year,
                month: curr.month,
                day: curr.day,
                title: `${curr.week_name} ${curr.day} ${curr.month} ${curr.year}`,
                unit: 'MMS',
            })
        }

        return acc
    }, []),
};

const mutations = {
    SAVE_USAGES(state, usages) {
        state.usage = usages;
    },

    SAVE_USAGE_CHARGES(state, usage_charges) {
        state.usage_charges.fix(usage_charges)
    },

    RESET_USAGE_CHARGES(state) {
        state.usage_charges.reset()
    },

    SET_IS_GETTING_USAGES(state, is_getting_usage) {
        state.is_getting_usage = is_getting_usage;
    },

    SET_IS_GETTING_USAGE_CHARGES(state, is_getting_usage_charges) {
        state.is_getting_usage_charges = is_getting_usage_charges;
    },
};

const actions = {
    getUsageCharges({ state, getters, commit, dispatch }, { FromNanos, ToNanos, data=true, mins=true, txts=true, pxts=true }={}) {
        if (!state.usage_charges.expired() && getters.usage_charges.length) {
            return Promise.resolve(getters.usage_charges)
        }

        commit('SET_IS_GETTING_USAGE_CHARGES', true)

        const defaultFromNanos = getters.customer_current_product?.CreatedAtNanos || 0
        const defaultUptoNanos = new Date().getTime() * 1_000_000

        const From = FromNanos || defaultFromNanos
        const Upto = ToNanos || defaultUptoNanos
        const CustomerUUID = getters.current_account_uid
        const MobileUUID = getters.customer_current_mobile_product_uuid

        return axiosIntents.get(
            `/api/frontend/v1/customer/${CustomerUUID}/cellular/charges/${MobileUUID}`, { params: {
                From,
                Upto,
                data,
                mins,
                txts,
                pxts,
            }}
        ).then(usage_charges => {
            if (!usage_charges || !usage_charges.data || !usage_charges.data.length) {
                commit('SAVE_USAGE_CHARGES', [])

                return Promise.resolve([])
            }

            const usage_charges_mapped = usage_charges.data.map(usage_charge => {
                const date_and_time = new Date(usage_charge.DateAndTime)
    
                const map_obj = {
                    ...usage_charge,
                    DateAndTimeUTC: usage_charge.DateAndTime,
                    date_and_time: date_and_time,
                    date: formatDate(date_and_time, 'D MMM YYYY'),
                    year: formatDate(date_and_time, 'YYYY'),
                    month: formatDate(date_and_time, 'MMMM'),
                    day: formatDate(date_and_time, 'D'),
                    week_name: formatDate(date_and_time, 'ddd'),
                    time_with_day_part: formatDate(date_and_time, 'hh:mm A'),
                    event_time: date_and_time.getTime(),
                }
    
                delete map_obj.DateAndTime
    
                return map_obj
            })
    
            usage_charges_mapped.sort((a, b) => b.event_time - a.event_time)
    
            commit('SAVE_USAGE_CHARGES', usage_charges_mapped)

            return Promise.resolve(usage_charges_mapped)
        }).catch((error)=> {
            console.log('getUsageCharges error', error)

            commit('RESET_USAGE_CHARGES')
        }).finally(()=> {
            commit('SET_IS_GETTING_USAGE_CHARGES', false)
        })
    },

    async getUsageByTypeAndRange({ getters, commit, dispatch }, { period, ProductType }) {
	    commit('SAVE_USAGES', {});
	    commit('SET_IS_GETTING_USAGES', true);

        const date_now = new Date(new Date().setHours(0, 0, 0, 0)).getTime();

	    const body = {
	    	CustomerUUID: getters.current_account_uid,
	    	ProductInstanceUUID: getters.customer_current_mobile_product_uuid,
	    	ProductType,
	    	Range: {
	    		FirstNanos: (date_now - period * 24 * 60 * 60 * 1000) * 1000000,
	    		FinalNanos: (date_now - 1000) * 1000000,
	    	  },
	    	IntervalNanos: 24 * 60 * 60 * 1000 * 1000000,
	    };

        const stats_promises = Object.values(USAGES)
            .map(({ kind }) => kind)
            .map(kind => axios.post(`${API_V2}/:spid/usage/${kind}/histogram`, body));

        const rslt = await Promise.allSettled(stats_promises);
        const usages_keys = Object.keys(USAGES);
        const usage_date = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate());
        const usage_dates = Array.from({ length: 30 }, (_) => new Date(usage_date.setDate(usage_date.getDate() - 1)));

        const usages_with_stats = rslt.reduce((acc, curr, index) => {
            const { status } = curr;

            let bucket = DEFAULT_BUCKET;

            if (status === 'fulfilled' && curr.value.result.Buckets) {
                if (acc[usages_keys[index]]['kind'] === 'data') {
                    bucket = curr.value.result.Buckets.map(val => +(val / MEGA_BASE_2).toFixed(2)).reverse();
                } else if (acc[usages_keys[index]]['kind'] === 'mins') {
                    bucket = curr.value.result.Buckets.map(val => +(val / 60).toFixed(0)).reverse();
                } else {
                    bucket = [...curr.value.result.Buckets].reverse();
                }
            }

            bucket.forEach((val, i) => {
                acc[usages_keys[index]]['history'][usage_dates[i].toDateString()] = {
                    date: usage_dates[i],
                    usage: val,
                    timestamp: usage_dates[i].getTime(),
                };
            });

            return acc
            
        }, { ...USAGES });


	    commit('SET_IS_GETTING_USAGES', false);
        commit('SAVE_USAGES', usages_with_stats);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
