import i18n from '@/i18n'
import dynStorage from '@/utils/storage-dynamic';

export default {
    state: {
        language: { title: i18n.t('language.en'), code: 'en' },
    },

    getters: {
        available_language_codes: (state, getters) => 
            (getters.getAppEnvParam('AVAILABLE_LANGUAGES') || 'en').split(','),

        available_languages: (state, getters) => 
            getters.available_language_codes.map(code => ({
                title: i18n.t(`language.${code}`),
                code,
            })),

        filtered_languages: (state, getters) => 
            getters.available_languages.filter(language =>  language.code !== state.language.code),

        language: (state) => state.language,
    },

    actions: {
        SetLanguage({commit, dispatch, getters}, { language, updateMockupUserLanguage = true }){
            const language_code = getters.available_language_codes.indexOf(language) !== -1 
                                ? language
                                : 'en';

            commit('SET_LANGUAGE', language_code);

            if (getters.auth_user?.uid && updateMockupUserLanguage) {
                dispatch('saveMockupLanguage', language_code)
            }
        },
    },

    mutations: {
        SET_LANGUAGE(state, language_code) {
            i18n.locale = language_code;
            state.language = { title: i18n.t(`language.${language_code}`), code: language_code };

            dynStorage.set('language', language_code);
        }
    },
}