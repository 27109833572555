import axiosIntents from '@/utils/axios-intents'

export default {
    state: {},

    getters: {},

    actions: {
        validateGuest({}, { MSISDN, LookupID, UserToken } = {}) {
            return axiosIntents.post('/guest/validate', { MSISDN, LookupID, UserToken }).then(validationResponse => {
                if (!validationResponse?.data?.ResponseToken || !validationResponse?.data?.Valid) {
                    const reject_message = MSISDN
                                            ? 'not_valid_mobile_number'
                                            : LookupID
                                                ? LookupID.startsWith('INV') ? 'not_valid_invoice_number' : 'not_valid_account_number'
                                                : 'Provided data is not valid'

                    return Promise.reject(reject_message)
                }

                return validationResponse.data.ResponseToken
            })
        },

        guestVoucherRedeem({}, { Code, MSISDN, ResponseToken, LookupID } = {}) {
            return axiosIntents.post('/guest/redeem', { Code, MSISDN, LookupID, ResponseToken }).then(voucherRedeemResponse => voucherRedeemResponse?.data?.Attributes?.CreditCents || voucherRedeemResponse?.data?.Attributes?.RenewalCreditCents || 0)
        },

        guestCardTopUp({}, { Amount, MSISDN, ResponseToken } = {}) {
            return axiosIntents.post('/guest/topup', { Amount, MSISDN, ResponseToken }).then(guestCardTopUpResponse => {
                const data = guestCardTopUpResponse?.data

                if (!data) return Promise.reject('No Data')

                const { ClientSecret, OrderID } = data

                return { ClientSecret, OrderID }
            })
        },

        guestCardPayment({}, { Amount, LookupID, ResponseToken } = {}) {
            return axiosIntents.post('/guest/payment', { Amount, LookupID, ResponseToken }).then(guestCardPaymentResponse => {
                const data = guestCardPaymentResponse?.data

                if (!data) return Promise.reject('No Data')

                const { ClientSecret, OrderID } = data

                return { ClientSecret, OrderID }
            })
        },

        guestLookupPurchase({}, { MSISDN, LookupID, OrderID } = {}) {
            return axiosIntents.get('/guest/purchases', { params: { MSISDN, LookupID, OrderID }}).then(guestLookupPurchaseResponse => {
                const data = guestLookupPurchaseResponse?.data

                if (!data) return Promise.reject('No Data')

                const { Status } = data

                return Status
            })
        },
    },

    mutations: {},
}
