import axiosUnique from '@/utils/axiosUnique'
import md5 from 'md5';

const HOTRELOAD_INTERVAL = process.env.VUE_APP_HOTRELOAD_INTERVAL
    ? (parseInt(process.env.VUE_APP_HOTRELOAD_INTERVAL) || 0) * 1000
    : 0

const state = {}

const getters = {
    hot_reload_interval: () => HOTRELOAD_INTERVAL
}

const mutations = {}

const actions = {
    getIndexMD5({}, no_cache) {
        return axiosUnique.get(location.origin + '?' + Date.now(),
            no_cache ? {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
            } : {},
        ).then(({data}) => {
            return Promise.resolve(md5(data))
        })
    },

    hotReload({dispatch}) {
        if (HOTRELOAD_INTERVAL) {
            return new Promise((resolve, reject) => {
                dispatch('getIndexMD5', false)
                    .then(initialIndexMD5 => {
                        const checkingIndexID = setInterval(() => {
                            dispatch('getIndexMD5', true)
                                .then(currentIndexMD5 => {
                                    if (initialIndexMD5 !== currentIndexMD5) {
                                        clearInterval(checkingIndexID)

                                        setTimeout(() => {
                                            resolve(currentIndexMD5)
                                        }, 5 * 60 * 1000) // 5 min delay to avoid reloading during deployment
                                    }
                                })
                                .catch(() => {})
                        }, HOTRELOAD_INTERVAL)
                    })
                    .catch(error => reject(error))
            })
        } else {
            return Promise.reject(HOTRELOAD_INTERVAL)
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
