const state = {
    is_v_tour_profile_account_menu_finished: false,
    is_v_tour_profile_mobile_menu_finished: false,
    is_restart_v_tour: false,
}

const getters = {
    is_v_tour_profile_account_menu_finished: (state) => state.is_v_tour_profile_account_menu_finished,
    is_v_tour_profile_mobile_menu_finished: (state) => state.is_v_tour_profile_mobile_menu_finished,
    is_restart_v_tour: (state) => state.is_restart_v_tour,
}

const actions = {}

const mutations = {
    SET_IS_V_TOUR_PROFILE_ACCOUNT_MENU_FINISHED: (state, is_v_tour_profile_account_menu_finished) => {
		state.is_v_tour_profile_account_menu_finished = is_v_tour_profile_account_menu_finished
	},

    SET_IS_V_TOUR_PROFILE_MOBILE_MENU_FINISHED: (state, is_v_tour_profile_mobile_menu_finished) => {
		state.is_v_tour_profile_mobile_menu_finished = is_v_tour_profile_mobile_menu_finished
	},

    SET_IS_RESTART_V_TOUR: (state, is_restart_v_tour) => {
		state.is_restart_v_tour = is_restart_v_tour
	},

    RESET_IS_V_TOUR_FINISHED_STATE: (state) => {
		state.is_v_tour_profile_account_menu_finished = false
		state.is_v_tour_profile_mobile_menu_finished = false
        state.is_restart_v_tour = false
	},
}

export default {
    state,
    getters,
    mutations,
	actions,
};