import store from '@/store'

export default (to, from, next) => {
    let eshop_purchased = store.getters.eshop_v2_purchased

    if (!eshop_purchased) {
        store.commit('eshopPurchasedLoad_v2')
        eshop_purchased = store.getters.eshop_v2_purchased
    }

    return {
        allow: Boolean(eshop_purchased),
        redirect: { name: 'home' }, // @todo сделать редирект на { name: 'eshop' }
                                    //       когда появятся другие продукты, кроме SIM
    }
};
