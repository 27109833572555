const REFRESHING_TIME = 30 * 24 * 60 * 60 *1000      // 1 month
const REFRESHING_TIME_COOKIES = 3 * REFRESHING_TIME  // 3 months

/**
 * Returns an expire date in Date format
 * @param {(number|date|string)} expire 
 * @returns {date} expire date in Date format
 */
const getExpireDate = (expire, default_refreshing = REFRESHING_TIME) => {
    if (expire === -1 || expire === Infinity || expire === false) { // never expire
        return null
    }
    if (expire instanceof Date) {                                   // expire in Date format
        return expire
    }
    if (!isNaN(expire) && +expire > 0) {                            // is a number of milliseconds
        const ms = parseInt(expire)
        if (ms - default_refreshing > Date.now()) {                 // expire is exact date as milliseconds
            return new Date(ms)
        } else {
            return new Date(Date.now() + ms)                        // expire is a number of ms from now
        }
    }
    if (typeof expire === 'string') {
        if (!isNaN(new Date(expire))) {                             // expire is a Date in String format
            return new Date(expire)
        }
        if (expire.match(/^(\d+(y|m(in)?|d|h|s))+$/ig)) {           // is string like "1y2m3d4h5min40s"
            const time_items = expire.toLowerCase()
                .match(/\d+(y|m(in)?|d|h|s)/ig)
                .reduce((acc, item) => {
                    const [value, time_item] = item.match(/(\d+|[ymindhs]+)/ig)
                    acc[time_item] = +value
                    return acc
                }, {y:0, m:0, d:0, h:0, min: 0, s: 0})

            const now     = new Date()
            const year    = now.getFullYear() + time_items.y
            const month   = now.getMonth() + time_items.m
            const day     = now.getDate() + time_items.d
            const hours   = now.getHours() + time_items.h
            const minutes = now.getMinutes() + time_items.min
            const seconds = now.getSeconds() + time_items.s

            return new Date(year, month, day, hours, minutes, seconds)
        }
    }

    return new Date( Date.now() + default_refreshing )             // default refreshing time
}
class Storage {
    /**
     * @param {string} priority - "localStorage" or "cookies" (def. localStorage)
     */
    constructor(priority) {
        this.$storage = null

        this.useCookies = false
        this.useLocalStorage = false

        switch (priority) {
            case 'cookies': {
                this.$storage = new StorageCookies()
                this.useCookies = true
            } break

            default: {
                let storage,
                    is_storage_worked = false

                const date = new Date
            
                try {
                    switch (priority) {
                        case 'sessionStorage': { storage = window.sessionStorage } break
                        default:               { storage = window.localStorage } break
                    }
                    storage.setItem(date, date)

                    is_storage_worked = storage.getItem(date) == date

                    storage.removeItem(date)
                } catch (e) {
                    console.warn('e')
                }

                if (is_storage_worked && storage) {
                    this.$storage = new StorageLocal(storage)
                    this.useLocalStorage = true
                }
            }
        }

        if (this.$storage === null) {
            this.$storage = new StorageCookies()
            
            this.useCookies = true
            this.useLocalStorage = false
        }
    }

    get(key, json_parse)                    { return this.$storage.get(key, json_parse)                    }
    set(key, value, expire, json_stringify) { return this.$storage.set(key, value, expire, json_stringify) }
    remove(key)                             { return this.$storage.remove(key)                             }

    push(key, value, expire) {
        let target = this.$storage.get(key, true)

        if (target) {
            if (Array.isArray(target)) {
                target.push(value)
            } else {
                throw new Error(`Target of pushing "${ key }" is not an array`)
            }
        } else {
            target = [ value ]
        }

        this.$storage.set(key, target, expire, true)
    }

    removeItem(key, value) {
        let target = this.$storage.get(key, true)

        if (target) {
            if (Array.isArray(target)) {
                const index = value instanceof Object
                    ? target.findIndex(item => {
                        let is_match = true

                        for (let key in value) {
                            if (item[key] !== value[key]) {
                                is_match = false
                                break
                            }
                        }

                        return is_match
                    })
                    : target.findIndex(item => item === value)

                if (index > -1) {
                    target.splice(index, 1)

                    this.$storage.set(key, target, null, true)
                }
            } else {
                throw new Error(`Target of removing item "${ key }" is not an array`)
            }
        }
    }
}

/**
 * Interface for working with vue-cookies
 */
class StorageCookies {
    constructor(storage) {
        this.$storage = $cookies
    }

    get(key, json_parse) {
        let value = this.$storage.get(key)

        if (value && json_parse) {
            try {
                value = JSON.parse(value)
            } catch (e) {}
        }

        return value
    }

    set(key, value, expire, json_stringify) {
        this.$storage.set(
            key,
            json_stringify
                ? JSON.stringify(value)
                : value,
                getExpireDate(expire, REFRESHING_TIME_COOKIES)
        )

        return this
    }
    
    remove(key) {
        this.$storage.remove(key)

        return this
    }
}

/**
 * Interface for working with localStorage
 */
class StorageLocal {
    constructor(storage) {
        this.$storage = storage
    }

    expireKey(key) {
        return `${key}-expire`
    }

    get(key, json_parse) {
        let value = this.$storage.getItem(key)

        if (value) {
            const expiry = this.getExpiry(key)

            if (expiry && (Date.now() > expiry)) {
                value = null

                this.remove(key)
            }
        }

        if (value && json_parse) {
            try {
                value = JSON.parse(value)
            } catch (e) {}
        }

        return value
    }

    getExpiry(key) {
        return this.$storage.getItem(this.expireKey(key))
    }

    set(key, value, expire, json_stringify) {
        this.$storage.setItem(
            key,
            json_stringify
                ? JSON.stringify(value)
                : value
        )

        if (expire) {
            this.$storage.setItem(this.expireKey(key), getExpireDate(expire).getTime())
        }

        return this
    }

    remove(key) {
        this.$storage.removeItem(key)
        this.$storage.removeItem(this.expireKey(key))

        return this
    }
}

// export default new Storage('cookies')
// export default new Storage('localStorage')
export default Storage