import { vuexfireMutations } from 'vuexfire'
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

import modules from './modules'

Vue.use(Vuex)
Vue.use(VueCookies)

export default new Vuex.Store({
    modules,
    state: {
    },
    mutations: {
        ...vuexfireMutations,
    },
    actions: {
    },
})