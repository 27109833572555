const international_plans = [
    {
        location: 'australia',
        price: '0',
        rate: 'c_min'
    },
    {
        location: 'bangladesh',
        price: '5',
        rate: 'c_min'
    },
    {
        location: 'canada',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'china',
        price: '3',
        rate: 'c_min'
    },
    {
        location: 'cook',
        price: '1.20',
        rate: 'c_min'
    },
    {
        location: 'fiji',
        price: '29',
        rate: 'c_min'
    },
    {
        location: 'french_polynesia',
        price: '60',
        rate: 'c_min'
    },
    {
        location: 'hawaii',
        price: '5',
        rate: 'c_min'
    },
    {
        location: 'hong_kong',
        price: '5',
        rate: 'c_min'
    },
    {
        location: 'india',
        price: '3',
        rate: 'c_min'
    },
    {
        location: 'macau',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'malaysia',
        price: '5',
        rate: 'c_min'
    },
    {
        location: 'nepal',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'niue',
        price: '1.60',
        rate: 'c_min'
    },
    {
        location: 'pakistan',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'samoa',
        price: '60',
        rate: 'c_min'
    },
    {
        location: 'singapore',
        price: '5',
        rate: 'c_min'
    },
    {
        location: 'sri_lanka',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'taiwan',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'tonga',
        price: '60',
        rate: 'c_min'
    },
    {
        location: 'uae',
        price: '40',
        rate: 'c_min'
    },
    {
        location: 'uk',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'usa',
        price: '20',
        rate: 'c_min'
    },
    {
        location: 'vietnam',
        price: '20',
        rate: 'c_min'
    },
];

international_plans.sort((a, b) => a.location.localeCompare(b.location))

export default international_plans;