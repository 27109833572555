const SPID = +process.env.VUE_APP_SPID

export const AVAILABLE_SIM_TYPE_PRODUCTS = {
    e_sim: {
        SPID,
        "UUID": "c7152d4a-8fa5-4ebc-8f8d-9016920b4c03",
        "CreatedAtNanos": 1667218163615738396,
        "UpdatedAtNanos": 1667218163615738396,
        "DeletedAtNanos": 0,
        "CategoryUUID": "3f689dc2-8564-4c62-befa-58c51c71b65e",
        "Name": "eSIM",
        "Brand": "",
        "Description": "Test description for eSIM product",
        "ItemAttributeSetUUID": "51848552-1ead-48a0-9139-c50d4ae95185",
        "ItemImages": [
            {
            "UUID": "41673081-02fc-414d-94a5-ff2d651bd6d0",
            "Name": "",
            "Orig": "",
            "XS": "",
            "S": "",
            "M": "",
            "L": "",
            "XL": ""
            }
        ],
        "Type": "TYPE_PRODUCT",
        "VirtualType": "VT_ESIM",
        "SelfManaged": true
    },

    real_sim: {
        SPID,
        "UUID": "bd218743-e96c-4a92-8a02-4f4af788c204",
        "CreatedAtNanos": 1667218239428906977,
        "UpdatedAtNanos": 1667218239428906977,
        "DeletedAtNanos": 0,
        "CategoryUUID": "3f689dc2-8564-4c62-befa-58c51c71b65e",
        "Name": "Physical SIM",
        "Brand": "",
        "Description": "",
        "ItemAttributeSetUUID": "51848552-1ead-48a0-9139-c50d4ae95185",
        "ItemImages": [
          {
            "UUID": "c5e4a886-0149-4010-8e8f-0f137fe23d14",
            "Name": "",
            "Orig": "",
            "XS": "",
            "S": "",
            "M": "",
            "L": "",
            "XL": ""
          }
        ],
        "Type": "TYPE_PRODUCT",
        "VirtualType": "VT_ESIM",
        "SelfManaged": true
    },
}

export const AVAILABLE_SIM_TYPE_PRODUCTS_BY_UUID = {}
for (const type in AVAILABLE_SIM_TYPE_PRODUCTS) {
    AVAILABLE_SIM_TYPE_PRODUCTS_BY_UUID[ AVAILABLE_SIM_TYPE_PRODUCTS[type].UUID ] = AVAILABLE_SIM_TYPE_PRODUCTS[type]
}



export const ORDER_STATUSES = {
    CONFIRMED:          { title: 'confirmed',           color: 'var(--order-confirmed-color)'         },
    PAID:               { title: 'paid',                color: 'var(--order-paid-color)'              },
    PROCESSING:         { title: 'processing',          color: 'var(--order-processing-color)'        },
    COMPLETED:          { title: 'complited',           color: 'var(--order-complited-color)'         },
    PARTIALLY_SHIPPED:  { title: 'partially_shipped',   color: 'var(--order-partially-shipped-color)' },
    SHIPPED:            { title: 'shipped',             color: 'var(--order-shipped-color)'           },
    FAILED:             { title: 'feiled',              color: 'var(--order-feiled-color)'            },
    CANCELED:           { title: 'canceled',            color: 'var(--order-canceled-color)'          },
    UNKNOWN:            { title: 'unknown',             color: 'var(--order-unknown-color)'           },
    CART:               { title: 'cart',                color: 'var(--order-cart-color)'              },
}



import store from '@/store'

import i18n from '@/i18n'
import { isObject } from '@/helpers/object-type'

export const eshopCartItem = (product, details) => {
    const uuid = product.uuid

    return {
        uuid,
        quantity: 1,

        product,

        name: product.name,
        type: product.type, 
        price: product.price,

        specifications: product.specifications,

        ...details,
    }
}

import addDetailsToAvailablePlan from '@/views/profile/plans/addons/addDetailsToAvailablePlan'

export const eshopCartItemSIM = (sim_product, plan_product) => {
    const is_e_sim = sim_product.UUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID
    const availability = plan_product?.Availabilities?.[0]

    const sim = {
        UUID: null,
        ItemUUID: sim_product.UUID,
        PriceCents: sim_product.PriceCents || 0,
        Quantity: 1,
        SPID: store.getters.app_spid,
        ProductAvailabilityUUID: availability?.UUID,
        ProductCatalogUUID: availability?.ProductCatalogEntryUUID,
        ...(is_e_sim ? {
            Description: 'eSIM',
            Name: 'eSIM',
            Type: 'TYPE_VIRTUAL',
            VirtualType: 'VT_ESIM',
        } : {
            Description: 'SIM Card',
            Name: 'SIM Card',
            Type: 'TYPE_PRODUCT',
        }),
    }

    return {
        id: sim.ItemUUID,
        uuid: sim.ItemUUID,
        name: sim.Name,
        price: sim.PriceCents / 100,
        type: is_e_sim ? 'esim' : 'sim',
        item: sim,
    }
}
export const eshopCartItemPlan = (plan_product) => {
    const availability = plan_product.Availabilities[0]

    let plan_name = `${availability.RetailName} (${process.env.VUE_APP_ESHOP_CART_ITEM_PREPAY})`
    let attributes_billingReference = 'prepay'

    if (plan_product.is_payg) {
        plan_name = `${availability.RetailName} (Payg)`
        attributes_billingReference = 'payg'
    } else if (plan_product.ProductType == 'postpaymobile') {
        plan_name = `${availability.RetailName} (Postpay)`
        attributes_billingReference = 'postpay'
    }

    if (plan_product.merged_name) {
        plan_name = plan_product.merged_name
    }

    const plan = {
        // AmountCents: "string",
        // AmountTaxCents: "string",
        // Brand: "string",
        // CreatedAtNanos: "string",
        Description: availability.RetailDescription,
        // ItemImages: ["string"],
        ItemUUID: availability.UUID,
        // ItemVariantUUID: "string",
        // MSISDN: "string",
        Name: plan_name,
        PriceCents: availability.RetailCents,
        PriceCatalogCents: plan_product.price_catalog_cents,
        ProductAvailabilityUUID: availability.UUID,
        ProductCatalogUUID: availability.ProductCatalogEntryUUID,
        Quantity: 1,
        // SIMID: "string",
        SPID: store.getters.app_spid,
        // Shipped: "string",
        Type: 'TYPE_VIRTUAL',
        UUID: null,
        // UpdatedAtNanos: "string",
        VirtualType: 'VT_PLAN',
        Attributes: {
            BillingReference: attributes_billingReference,
            MobileProductAvailabilityUUID: availability.UUID,
            MobileProductCatalogUUID: availability.ProductCatalogEntryUUID,
            AutoRenew: plan_product.is_autoRenew ?? true,
        },
    }

    const details = plan_product.details || addDetailsToAvailablePlan(plan_product).details || {}
    let specifications = {
        data: (isObject(details[`${ i18n.t('data') }:`]) 
                ? details[`${ i18n.t('data') }:`]?.value 
                : details[`${ i18n.t('data') }:`]),

        mms: (isObject(details[`${ i18n.t('MMS2') }:`]) 
                ? details[`${ i18n.t('MMS2') }:`]?.value 
                : details[`${ i18n.t('MMS2') }:`]),

        sms: (isObject(details[`${ i18n.t('SMS2') }:`]) 
                ? details[`${ i18n.t('SMS2') }:`]?.value 
                : details[`${ i18n.t('SMS2') }:`]),

        voice: (isObject(details[`${ i18n.t('voice2') }:`]) 
                ? details[`${ i18n.t('voice2') }:`]?.value 
                : details[`${ i18n.t('voice2') }:`]),

        international_SMS: (isObject(details[`${ i18n.t('international_SMS') }:`]) 
                ? details[`${ i18n.t('international_SMS') }:`]?.value 
                : details[`${ i18n.t('international_SMS') }:`]),

        international_voice: (isObject(details[`${ i18n.t('international_voice') }:`]) 
                ? details[`${ i18n.t('international_voice') }:`]?.value 
                : details[`${ i18n.t('international_voice') }:`]),
    }

    const speedLimitDL = plan_product?.Characteristics['ocs.SpeedLimitDL']

    let speedLimitToShow = ''

    if (speedLimitDL) {
        speedLimitToShow = speedLimitDL == 'Unlimited' ? `- ${i18n.t('max_spid')}` : `${i18n.t('up_to_bps', {speedLimitDL})}`
    }

    const unlimited = i18n.t('unlimited')
    const casual_rate = i18n.t('casual_rate')
    for (const key in specifications) {
        if (specifications[key] == null) {
            delete specifications[key]
        } else {
            if (i18n.t(key) == i18n.t('data')) {
                specifications[key]+= ` ${i18n.t(key)} ${speedLimitToShow}`
            } else {
                if (specifications[key] == unlimited) {
                    specifications[key]+= ` ${ i18n.t(key) }`
                } else if (specifications[key] == casual_rate) {
                    specifications[key]+= ` ${ i18n.t(key) }`
                }

                if (key == 'international_SMS' || key == 'international_voice') {
                    if (!(specifications[key]?.startsWith(unlimited) || specifications[key]?.startsWith(casual_rate))) {
                        specifications[key]+= ` ${i18n.t('international')}`
                    }
                } else {
                    specifications[key]+= ` ${i18n.t('to_NZ_and_AU')}`
                }
            }
        }
    }

    return {
        id: plan.ItemUUID,
        uuid: plan.ItemUUID,
        name: plan.Name,
        price: plan.PriceCents / 100,
        specifications: Object.values(specifications),
        type: 'plan',
        item: plan,
    }
}



export const eshopItem = (eshop_item, details) => {
    /**
     * @todo расширить на работу со всеми типами товаров, когда они появятся в АПИ
     */
    let item = null

    const sim_product = eshop_item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.e_sim.UUID
        ? {...AVAILABLE_SIM_TYPE_PRODUCTS.e_sim, PriceCents: eshop_item.PriceCents}
        : eshop_item.ItemUUID == AVAILABLE_SIM_TYPE_PRODUCTS.real_sim.UUID
            ? {...AVAILABLE_SIM_TYPE_PRODUCTS.real_sim, PriceCents: eshop_item.PriceCents}
            : null

    const eshop_item_product_catalog_uuid = eshop_item.ProductCatalogUUID || eshop_item.Attributes?.MobileProductCatalogUUID
    const eshop_item_product_availability_uuid = eshop_item.ProductAvailabilityUUID || eshop_item.Attributes?.MobileProductAvailabilityUUID

    const plan_product = eshop_item_product_catalog_uuid && eshop_item_product_availability_uuid
        ? store.getters.all_services.find(item =>
            item.UUID == eshop_item_product_catalog_uuid
            && item.Availabilities?.[0]?.UUID == eshop_item_product_availability_uuid
        )
        : null

    return sim_product
        ? eshopCartItem(eshopCartItemSIM(sim_product, plan_product), {
            quantifiable: false,
            ...details,
        })
        : plan_product && eshop_item.VirtualType == 'VT_PLAN'
            ? eshopCartItem(eshopCartItemPlan({...plan_product, merged_name: eshop_item.Name}), {
                removable: false,
                quantifiable: false,
                ...details,
            })
            : null
}

export const logAnalyticsItems = (items) => Array.isArray(items)
    ? items.reduce((acc, item) => {
        console.log('logAnalyticsItems')
        
        const accPush = item => {
            const item_id = item.ItemUUID || ''

            const item_acc = item_id
                ? acc.find(item => item.item_id == item_id)
                : null

            if (item_acc) {
                item_acc.quantity+= item.Quantity || 0
            } else {
                acc.push({
                    item_id,
                    item_name: item.Name || '',
                    quantity: item.Quantity || 0,
                    price: (item.PriceCents || 0) / 100,
                })
            }
        }

        accPush(item)

        if (item.Products?.length) {
            accPush(item.Products[0])
        }

        return acc
    }, [])
    : null