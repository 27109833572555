import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

import i18n from '@/i18n';

let cashed = {
    network_services_maps: {
        outage: new StoreHash(5 * 60 * 1000), // 5 min,
        coverage: new StoreHash(5 * 60 * 1000), // 5 min,
    },
}

const NETWORK_SERVICES_MAPS_API = process.env.VUE_APP_NETWORK_SERVICES_MAPS_API
const NETWORK_SERVICES_MAPS_PROXY = process.env.VUE_APP_NETWORK_SERVICES_MAPS_PROXY

const state = {
    network_services_maps: {
        outage: {},
        coverage: {},
    },
}

const getters = {
    network_outage_maps: state => state.network_services_maps.outage,
    NETWORK_COVERAGE_MAPs: state => state.network_services_maps.coverage,
    network_services_maps: state => ({...state.network_services_maps.outage, ...state.network_services_maps.coverage}),
    networkServiceMaps: state => service => state.network_services_maps[service],

    NETWORK_SERVICES_MAPS_API: state => NETWORK_SERVICES_MAPS_API,
    NETWORK_SERVICES_MAPS_PROXY: state => NETWORK_SERVICES_MAPS_PROXY,
}

const mutations = {
    saveNetworkServicesMaps(state, { service, network_maps }) {
        state.network_services_maps[service] = network_maps

        cashed.network_services_maps[service].fix()
    },

    resetCashedNetworkServiceMaps(state, service) {
        state.network_services_maps[service] = {}

        cashed.network_services_maps[service].reset()
    },

    resetCashedNetworkServicesMaps(state) {
        state.network_services_maps.outage = {}
        state.network_services_maps.coverage = {}

        cashed.network_services_maps.outage.reset()
        cashed.network_services_maps.coverage.reset()
    },
}

const actions = {
    getNetworkServiceMaps({getters, commit}, { service, x_api_key }) {
        if (cashed.network_services_maps[service].expired()) {
            const NETWORK_SERVICE_MAPS_URL = (NETWORK_SERVICES_MAPS_PROXY ? NETWORK_SERVICES_MAPS_PROXY + '?' : '')
                + NETWORK_SERVICES_MAPS_API
                + `/${ service }-maps`
                + encodeURI('?f=json&_ts=') + Date.now()
            
            return axiosUnique.get(NETWORK_SERVICE_MAPS_URL, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    ...(x_api_key ? {
                        'x-api-key': x_api_key,
                    } : {})
                },
            }).then(({data}) => {
                if (data.error) {
                    return Promise.reject(new Error(`${ i18n.t('error_while_loading_google_maps') } ${ data.error.message }. Code: ${ data.error.code }`))
                } else {
                    let network_maps = {}
                    
                    for (let i = 0, len = data.services.length; i < len; i++) {
                        if (data.services[i].type === 'MapServer') {
                            const service_map_parts = data.services[i].name.split('/')
                            const service_map = service_map_parts[service_map_parts.length - 1]
                            
                            var parts = service_map.split('_')
                            // example name will be Mobile_2G_0_T_eb9700 or RuralqqBroadband_NoqqAerial_1_d_990000 (qq is used as no other sensible delimiter is valid)
                            if (parts && parts.length === 5) {
                                const is_outage = service == 'outage'

                                const service_type = parts[0]
                                const map_name = is_outage ? parts[0] : parts[1]
                                const layer_order = parseInt(parts[2])
                                const layer_type = parts[3].toLowerCase()
                                const colour = '#' + parts[4]
    
                                if (!(map_name in network_maps)) {
                                    network_maps[map_name] = {
                                        id: map_name,
                                        type: service_type,
                                        name: is_outage ? 'Outages' : map_name.replace(/qq/g, ' '),
                                        colour,
                                        layers: []
                                    }
                                }
    
                                network_maps[map_name].layers.push({
                                    url: `${ NETWORK_SERVICES_MAPS_API }/${ service }-maps/${ service_map }/MapServer`,
                                    type: layer_type,
                                    order: layer_order,
                                })
                            }
                        }
                    }
    
                    for (const map_name in network_maps) {
                        network_maps[map_name].layers.sort((a,b) => a.order - b.order)
                    }

                    commit('saveNetworkServicesMaps', { service, network_maps })

                    return Promise.resolve(getters.networkServiceMaps(service))
                }
            }).catch(error => {
                commit('resetCashedNetworkServiceMaps', service)

                return Promise.reject(error)
            })
        } else {
            return Promise.resolve(getters.networkServiceMaps(service))
        }
    },

    getNetworkOutageMaps({dispatch}, x_api_key) {
        return dispatch('getNetworkServiceMaps', { service: 'outage', x_api_key })
    },
    getNetworkCoverageMaps({dispatch}, x_api_key) {
        return dispatch('getNetworkServiceMaps', { service: 'coverage', x_api_key })
    },
    getNetworkServicesMaps({dispatch}, x_api_key) {
        return Promise.all([
            dispatch('getNetworkOutageMaps', x_api_key),
            dispatch('getNetworkCoverageMaps', x_api_key),
        ])
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}