import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/remote-config';

import 'firebase/auth';
import 'firebase/analytics';
import "firebase/performance";

const firebase_config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export const db = firebase.initializeApp(firebase_config).firestore();

firebase.firestore().settings({ experimentalAutoDetectLongPolling: true });

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // 1 hour

remoteConfig.defaultConfig = {
    hide_prepay_balance_if_zero: true,
    hide_prepay_topup: true,
    is_show_recap: false,
};

export const auth = firebase.auth;

let analytics = null
let perf = null

if (process.env.VUE_APP_HAS_FIREBASE_ANALYTICS == 'true') {
    const google_analytics_link = document.createElement('link')
    google_analytics_link.rel = 'preconnect'
    google_analytics_link.href = 'https://www.google-analytics.com'

    document.getElementsByTagName('head')[0].appendChild(google_analytics_link)

    analytics = firebase.analytics();
}

if (process.env.VUE_APP_HAS_FIREBASE_PERFORMANCE == 'true') {
    perf = firebase.performance();
}

export { analytics };
export { perf };
export const fb_remote_config = remoteConfig;
