import axios from 'axios';

const API_V2 = 'v2';
// const API_V3 = 'v3';

export default {
	state: {},

	getters: {},

	actions: {
		updateCurrentAccountProfile({ getters }, { FirstName, LastName, Email }) {
			const SPID = getters.app_spid;

			const JWT = getters.JWT;

			return axios.put(
				`${API_V2}/${SPID}/customer/${getters.current_account_uid}`,
				{
				  SPID,
				  FirstName,
				  LastName,
				  Email,
				},
				{
				  headers: {
				    'x-auth-token': JWT,
				  },
				},
			);
		}
	},

	mutations: {},
}