import i18n from '@/i18n'

const CLIENT_ID = process.env.VUE_APP_APPLE_CLIENT_ID
const SCOPES = 'name email'
const STATE = '1111'

const state = {
    AppleID: null,
}

const getters = {
}

const mutations = {
    saveAppleId(state, AppleID) {
        state.AppleID = AppleID
    }
}

const actions = {
    appleInit({state, commit}) {
        if (state.AppleID) {
            return Promise.resolve(AppleID)
        } else {
            return new Promise((resolve, reject) => {
                const first_document_script = document.getElementsByTagName('script')[0]
                const id = 'apple-jsapi'
                const apple_js_script = document.getElementById(id) || document.createElement('script')
                if (!apple_js_script.id) {
                    apple_js_script.id = id
                    apple_js_script.src = "//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
                    first_document_script.parentNode.insertBefore(apple_js_script, first_document_script)
                }

                apple_js_script.onload = function() {
                    window.AppleID.auth.init({
                        clientId : CLIENT_ID,
                        scope : SCOPES,
                        redirectURI : window.location.href,
                        state : STATE,
                        // nonce : NONCE,
                        usePopup : true //or false defaults to false
                    })

                    commit('saveAppleId', window.AppleID)
                    resolve(window.AppleID)
                }

                apple_js_script.onerror = function() {
                    reject(i18n.t('apple_script_load_error'))
                }

            })
        }
    },

    appleSignIn({dispatch}, options) {
        return new Promise((resolve, reject) => {
            dispatch('appleInit').then(AppleID => {

                AppleID.auth.signIn().then(({authorization, user}) => {
                    resolve(authorization)
                }).catch( ({error}) => reject(error))

            })
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}