const state = {};

const getters = {};

const mutations = {};

const actions = {
  facebookAuth({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('facebookSignIn')
        .then((signin) => {
          dispatch('facebookMe')
            .then((me) => {
              dispatch('AuthCheck', {
                IdentityProvider: 'FACEBOOK',
                AuthID: signin.authResponse.userID,
                AuthNonce: signin.authResponse.accessToken,
              })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            })
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    });
  },

  appleAuth({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('appleSignIn')
        .then((authorisation) => {
          dispatch('AuthCheck', {
            IdentityProvider: 'APPLE',
            AuthID: authorisation.id_token,
            AuthNonce: authorisation.code,
          })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    });
  },

  googleAuth({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('googleSignIn')
        .then((GoogleUser) => {
          const BasicProfile = GoogleUser.getBasicProfile();

          dispatch('AuthCheck', {
            IdentityProvider: 'GOOGLE',
            AuthID: GoogleUser.getId() || 'anonymous',
            AuthNonce: GoogleUser.getAuthResponse().id_token,
          })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
