import axiosIntents from '@/utils/axios-intents'
import { db } from '@/utils/firebase.js'
import { firestoreAction } from 'vuexfire';

import dynStorage from '@/utils/storage-dynamic'

import i18n from '@/i18n'

const MILLISECONDS_IN_A_DAY = 86400000;

const mapPrapayUsage = usage => {
    const getUsageType = key => {
        switch (key) {
            case 'Data':
                return 'data'
            case 'Mins':
                return 'voice'
            case 'IDDMins':
                return 'idd_voice'
            case 'TXTs':
                return 'sms'
            case 'IDDTXTs':
                return 'idd_sms'
            case 'PXTs':
                return 'mms'
            default:
                return 'unknown'
        }
    }

    const mappedPrapayUsage = Object.keys(usage).map(key => {
        return {
            CarryOverAmount: usage[key]?.CarryOverAmount || 0,
            AmountRemaining: usage[key]?.RemainingAmount || 0,
            UsageType: getUsageType(key),
            UsageRule: usage[key]?.UsageRule,
        }
    })

    return mappedPrapayUsage
}

const state = {
    prepay_product: {},
}

const getters = {
    prepay_product: state => state.prepay_product,
    prepay_product_uuid: state => state.prepay_product.UUID,
    // prepay_product_addons: state => state.prepay_product?.AddOns || [],
    prepay_product_credit_expires_date: state => {
        if (!state.prepay_product) {
            return null
        } else if (state.prepay_product.CurrencyCreditExpiresAtNanos) {
            // if we have the credit expiry information, use it...
            const expires_at_nanos = state.prepay_product.CurrencyCreditExpiresAtNanos;
            const expires_at_miliseconds = Math.round(+expires_at_nanos/1000000);
            const expire_date = new Date(expires_at_miliseconds);
            if (expire_date < new Date()) return null;
            return expire_date;
        } else if (state.prepay_product.CreatedAtNanos) {
            // else, calculate the credit expiry from when the plan was created
            const Default_Credit_Expiry_Days = 365;

            const created_at_miliseconds = Math.round(+state.prepay_product.CreatedAtNanos/1000000);
            const Offer_period_miliseconds = +Default_Credit_Expiry_Days * MILLISECONDS_IN_A_DAY;

            const expire_date_in_miliseconds = created_at_miliseconds + Offer_period_miliseconds;
            const expire_date = new Date(expire_date_in_miliseconds);

            if (expire_date < new Date()) return null;
            return expire_date;
        } else {
            return null;
        }
    },
    customer_current_prepayed_plan_balance: (state, getters) => getters.customer_current_prepayed_plan?.MainBalCreditCents || 0,
}

const mutations = {
    savePrepayProduct(state, prepay_product) {
        state.prepay_product = prepay_product

        if (prepay_product) {
            dynStorage.set('prepay_product', prepay_product, null, true);
        } else {
            dynStorage.remove('prepay_product');
        }
    },

    resetPrepayProduct(state) {
        state.prepay_product = {}
    },
}

const actions = {
    getCurrentPrepayProduct({getters, commit}) {
        const uuid = getters.current_account_uid
        const current_product_uuid = getters.customer_current_product_uuid

        return uuid
            ? db.doc(`customers/${ uuid }`).get().then(customer => {
                return customer.ref.collection(getters.collection_customers_mobiles_name).get()
            }).then(products => {
                if (products.docs.length) {
                    const current_prepay_product = products.docs.filter(doc => {
                        console.log('getCurrentPrepayProduct')
                        console.log('doc.id', doc.id)
                        console.log('current_product_uuid', current_product_uuid)

                        return doc.id == current_product_uuid
                    })

                    commit('savePrepayProduct', current_prepay_product[0].data())

                    return Promise.resolve(getters.prepay_product)
                } else {
                    commit('resetPrepayProduct')

                    return Promise.reject(new Error(i18n.t('you_dont_have_prepay_product')))
                }
            }).catch(error => {
                console.log('ERROR', `customers/${ uuid }/prepay_products`, error)
                console.dir(error)

                commit('resetPrepayProduct')

                return Promise.reject(error)
            })
            : Promise.reject(new Error(i18n.t('current_account_not_specified')))
    },

    topUpByVoucherCode({getters, commit, dispatch}, code) {
        return dispatch('getCurrentPrepayProduct').then(prepay_product => {
            return axiosIntents.post(`/customer/:uuid/redeem_voucher/${ prepay_product.UUID }/${ code }`)
        }).then(({result}) => {
            // commit('SET_CUSTOMER_CURRENT_PREPAYED_PRODUCT_BALANCE', result.CreditCents)

            // commit('savePrepayProduct', result)

            return Promise.resolve(result)
        }).catch(error => Promise.reject(error))
    },

    topUpByCreditCard({ dispatch }, { card_token, cents }) {
        return dispatch('getCurrentPrepayProduct').then(prepay_product => {
            return axiosIntents.get(`/customer/:uuid/topup/${ prepay_product.UUID }/${ cents }`).then(({result}) => {
                return dispatch('stripePaymentConfirmation', {
                    card_token: card_token,
                    client_secret: result.ClientSecret,
                })
            })
        }).catch(error => Promise.reject(error))
    },

    topUpByCreditCardV2({ dispatch, getters }, { PaymentMethodType, PaymentMethodID,  Amount }) {
        return dispatch('getCurrentPrepayProduct').then(prepay_product => {
            const payload = {
                ProductInstanceUUID: prepay_product.UUID,
                Amount,
                PaymentMethodType,
                PaymentMethodID,
            }
            return axiosIntents.post(`/customer/:uuid/topup`, payload).then(({result}) => {
                if (result.ClientSecret) {
                    if (getters.defaultPaymentCardId) {
                        if (PaymentMethodType === 'cards') {
                            return dispatch('stripePaymentConfirmation', {
                                card_token: PaymentMethodID || getters.defaultPaymentCardId,
                                client_secret: result.ClientSecret,
                            })
                        } else {
                            return Promise.reject({
                                card_token: getters.defaultPaymentCardId,
                                client_secret: result.ClientSecret,
                                reason: 'changePaymentType',
                                amount: Amount,
                                last4: getters.defaultPaymentCardLast4,
                            })
                        }
                    } else {
                        return Promise.reject('No card')
                    }
                }
                return true
            })
        }).catch(error => Promise.reject(error))
    },

    GetPrepayProductUsage({ getters, commit }) {
        if (getters.is_getting_current_product_usage) return

        commit('SET_IS_GETTING_CUSTOMER_CURRENT_PRODUCT_USAGE', true)

        const current_mobile_product_uuid = getters.customer_current_product_uuid

        return axiosIntents.get(`/customers/:uuid/products/mobile/prepay/${current_mobile_product_uuid}/balance`).then(({result: usage}) => {
            commit('SET_IS_GETTING_CUSTOMER_CURRENT_PRODUCT_USAGE', false)

            if (!usage) {
                commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE',[])
                return Promise.resolve(null)
            }

            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE', mapPrapayUsage(usage))
            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_HAS_DATA', true)
            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_ERROR', null)

            return Promise.resolve(usage)
        }).catch(error => {
            console.error('GetPrepayProductUsage Error:', error)

            commit('SET_IS_GETTING_CUSTOMER_CURRENT_PRODUCT_USAGE', false)
            commit('SET_CUSTOMER_CURRENT_PRODUCT_USAGE_ERROR', error)

            return Promise.resolve(null)
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}