import Vue from 'vue'
import VueI18n from 'vue-i18n'

// TODO implement lazy loading of Mao (mi) when translation is provided
import { en, mi_NZ } from './locales'

Vue.use(VueI18n)

const messages = {
    en,
    'mi-NZ': mi_NZ,
}

/* console.log('messages.en keys', Object.keys(messages.en)
                .map(key => key.toLocaleLowerCase()).sort() ) */

/* console.log('messages.en values', 
                Object.values(messages.en).sort() ) */

// console.log('messages.en', messages.en)

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages,
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    missing: () => '',
})
