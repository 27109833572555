export default class StoreCache {
    /**
     * @param {int} lifetime
     */
    constructor(lifetime) {
        this.lifetime = lifetime
        this.reset()
    }

    reset() {
        this.loaded = null
        this.data = null
    }

    resetWithDefaultData(data) {
        this.loaded = null
        this.data = data
    }

    expireNow() {
        this.loaded = null
    }

    expired() {
        let expired = true
        if (this.loaded) {
            if ((new Date() - this.loaded) < this.lifetime) {
                expired = false
            } else {
                this.data = null
            }
        }

        return expired
    }

    fix(data) {
        this.data = data
        this.loaded = new Date()

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.data = null
        }, this.lifetime + 1000)

        return true
    }

    cachedData() {
        return this.data
    }

    cachedNotExpiredData() {
        if (!this.expired()) return this.cachedData()
    }

    static hash(obj) {
        return JSON.stringify(obj, function(key, value) {
            if (typeof value === 'function') {

                return value.toString();

            } else if (value?.toString()?.startsWith('[object ')) {

                return Object.keys(value).sort()
                    .reduce( ( acc, key ) => ( { ...acc, [key]: value[key] } ), {})

            } else {

                return value;

            }
        });
    }
}