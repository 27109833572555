import axios from 'axios';
import errMessage from '@/helpers/err-message'

const INVITE_STATUSES = {
    REQUEST:  { title: 'pending',  color: 'var(--invite-pending-color)', VALUE: 'REQUEST'   },
    APPROVED: { title: 'accepted', color: 'var(--invite-accepted-color)', VALUE: 'APPROVED' },
    REJECTED: { title: 'declined', color: 'var(--invite-declined-color)', VALUE: 'REJECTED' },
}

const state = {
    customer_groups_and_members: [],
    customer_invites: null,
}

const getters = {
    customer_groups_and_members: state => state.customer_groups_and_members,
    customer_sent_invites: state => state.customer_invites?.sent || [],
    customer_incoming_invites: state => state.customer_invites?.incoming || [],
    INVITE_STATUSES: () => INVITE_STATUSES,
}

const mutations = {
    SAVE_CUSTOMER_GROUPS_AND_MEMBERS(state, { customer_groups_and_members = [], current_account_uid } = {}) {
        if (!customer_groups_and_members.length) {
            state.customer_groups_and_members = []

            return
        }

        const customer_groups_and_members_mapped = customer_groups_and_members.map(({ CustomerGroup, CustomerGroupMembers }) => {
            let customer_group_members = CustomerGroupMembers || []

            if (current_account_uid) {
                const current_customer_group_member_index = customer_group_members.findIndex(group_member => group_member.CustomerUUID == current_account_uid)
    
                if (current_customer_group_member_index > -1) {
                    const current_customer_group_member = customer_group_members.splice(current_customer_group_member_index, 1)[0]

                    customer_group_members = [current_customer_group_member, ...customer_group_members]
                }
            }

            customer_group_members = customer_group_members.map(customer_group_member => {
                return {
                    ...customer_group_member,
                    is_leader: customer_group_member.CustomerUUID == CustomerGroup.CustomerUUID,
                }
            })

            return {
                ...CustomerGroup,
                is_group_owner: CustomerGroup.CustomerUUID == current_account_uid,
                members_total_items_count: customer_group_members.length,
                CustomerGroupMembers: customer_group_members,
            }
        })

        customer_groups_and_members_mapped.sort((a, b) => a.CreatedAtNanos - b.CreatedAtNanos)

        state.customer_groups_and_members = customer_groups_and_members_mapped
    },

    DELETE_CUSTOMER_GROUP_MEMBER(state, { group_uuid, customer_to_delete_uuid } = {}) {
        if (!group_uuid || !customer_to_delete_uuid) return

        const group_of_a_member = state.customer_groups_and_members.find(group => group.UUID == group_uuid)

        if (!group_of_a_member) return

        if (group_of_a_member.CustomerGroupMembers.length > 2) {
            group_of_a_member.CustomerGroupMembers = group_of_a_member.CustomerGroupMembers.filter(member => member.CustomerUUID != customer_to_delete_uuid)
            group_of_a_member.members_total_items_count = group_of_a_member.CustomerGroupMembers.length
        } else {
            // group_of_a_member.CustomerGroupMembers = []
            // group_of_a_member.members_total_items_count = 0

            state.customer_groups_and_members = state.customer_groups_and_members.filter(group => group.UUID != group_uuid)
        }
    },

    LEAVE_A_CUSTOMER_GROUP(state, group_uuid ) {
        state.customer_groups_and_members = state.customer_groups_and_members.filter(group => group.UUID != group_uuid)
    },

    RESET_CUSTOMER_GROUPS_AND_MEMBERS(state) {
        state.customer_groups_and_members = []
    },

    SAVE_CUSTOMER_INVITES(state, invites) {
        state.customer_invites = ({
            sent: invites?.Invites?.filter(invite => invite.ApprovalType == 'GROUP_INVITE').map(invite => ({...invite, invite_type: 'sent'})) || [],
            incoming: invites?.Approvals?.filter(invite => invite.ApprovalType == 'GROUP_INVITE').map(invite => ({...invite, invite_type: 'incoming'})) || [],
        })
    },

    RESET_CUSTOMER_INVITES(state) {
        state.customer_invites = null
    },
}

const actions = {
    GetCustomerGroupsAndMembers({ getters, commit }) {
        const CustomerUUID = getters.current_account_uid

        if (!CustomerUUID) {
            commit('SAVE_CUSTOMER_GROUPS_AND_MEMBERS', { customer_groups_and_members: [] })

            return Promise.reject("No CustomerUUID")
        }

        return axios.get(`/v2/:spid/customer_group_info/${CustomerUUID}`)
            .then(({ data }) => {
                if (!data) {
                    commit('SAVE_CUSTOMER_GROUPS_AND_MEMBERS', { customer_groups_and_members: [] })
                    
                    return Promise.reject("No data")
                }
                
                const { CustomerGroupInfos = [] } = data
                
                commit('SAVE_CUSTOMER_GROUPS_AND_MEMBERS', { customer_groups_and_members: CustomerGroupInfos, current_account_uid: CustomerUUID })
            })
            .catch(error => {
                commit('SAVE_CUSTOMER_GROUPS_AND_MEMBERS', { customer_groups_and_members: [] })

                if (error?.response?.status == 404 && errMessage(error) == 'Object not found') {
                    return Promise.resolve([])
                }

                return Promise.reject(error)
            })
    },

    LeaveCustomerGroup({ getters, dispatch }, { group_uuid } = {}) {
        if (!group_uuid) return Promise.reject("No params")

        const req = {
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
            CustomerGroupUUID: group_uuid,
        }

        return dispatch('api_customer/LeaveCustomerGroup', req)
    },

    RemoveOtherCustomerFromGroup({ getters, dispatch }, { group_uuid, customer_to_delete_uuid } = {}) {
        if (!group_uuid || !customer_to_delete_uuid) return Promise.reject("No params")

        const req = {
            SPID: getters.app_spid,
            CustomerUUID: getters.current_account_uid,
            OtherCustomerUUID: customer_to_delete_uuid,
            CustomerGroupUUID: group_uuid,
        }

        return dispatch('api_customer/RemoveOtherCustomerFromGroup', req)
    },

    GetCustomerInvites({ getters, dispatch, commit }) {
        const CustomerUUID = getters.current_account_uid
        const SPID = getters.app_spid
        
        return dispatch('api_approval/GetCustomerApprovals', { SPID, CustomerUUID }).then(invites => {
            commit('SAVE_CUSTOMER_INVITES', invites)

            return Promise.resolve(invites)
        }).catch(error => {
            console.log('GetCustomerInvites error:', error)

            commit('RESET_CUSTOMER_INVITES')

            return Promise.resolve([])
        })
    },

    SendCustomerInvite({ getters, dispatch }, { RequestedTo, ResourceUUID, Description = '' } = {}) {
        if (!RequestedTo) return Promise.reject("No params")

        const SPID = getters.app_spid
        const CustomerUUID = getters.current_account_uid

        const req = {
            SPID,
            CustomerUUID,
            RequestedTo,
            Description,
            ExpiresAtNanos: (new Date().getTime() + 60 * 60 * 1000) * 1000000,
            ApprovalType: 'GROUP_INVITE',
        }

        ResourceUUID ? req.ResourceUUID = ResourceUUID : req.ResourceDetails = 'COMPANION'

        return dispatch('api_approval/Invite', req)
    },

    ActionCustomerApproval({ getters, dispatch }, { UUID, Action } = {}) {
        if (!UUID) return Promise.reject("No UUID")

        const SPID = getters.app_spid
        const CustomerUUID = getters.current_account_uid

        const req = {
            SPID,
            CustomerUUID,
            UUID,
            Action,
        }

        return dispatch('api_approval/ActionApproval', req)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}