import { db } from '@/utils/firebase.js';
import { firestoreAction } from 'vuexfire';

import i18n from '@/i18n';

import dynStorage from '@/utils/storage-dynamic';

const COLLECTION_CUSTOMERS = 'customers';
const COLLECTION_CUSTOMER_PREPAY_PRODUCTS = 'prepay_products';
const COLLECTION_CUSTOMER_POSTPAY_PRODUCTS = 'postpay_products';
const COLLECTION_CUSTOMER_MOBIE_PRODUCTS = 'mobile_products';
const COLLECTION_CUSTOMER_PRODUCTS = 'products';

const COLLECTION_CUSTOMER_MOBIES = 'mobiles';

const COLLECTION_CUSTOMER_POSTPAY_CHANGE_PLAN = 'change_plan';
const DOC_CUSTOMER_POSTPAY_CHANGE_PLAN = 'request';

export default {
  state: {
    account_customers: [],
    account_customers_uuids: [],
  },

  getters: {
    collection_customers_name: (state) => COLLECTION_CUSTOMERS,
    collection_customers_products_name: (state) => COLLECTION_CUSTOMER_PRODUCTS,
    collection_customers_prepay_products_name: (state) => COLLECTION_CUSTOMER_PREPAY_PRODUCTS,
    collection_customers_postpay_products_name: (state) => COLLECTION_CUSTOMER_POSTPAY_PRODUCTS,
    collection_customers_mobile_products_name: (state) => COLLECTION_CUSTOMER_MOBIE_PRODUCTS,
    collection_customers_mobiles_name: (state) => COLLECTION_CUSTOMER_MOBIES,

    account_customers: state => state.account_customers,
    account_customers_uuids: (state) => {
      return state.account_customers && state.account_customers.length
        ? state.account_customers.map(customer => customer.CustomerUUID)
        : state.account_customers_uuids && state.account_customers_uuids.length
          ? state.account_customers_uuids
          : []
  },

    collection_customers_postpay_change_plan_name: (state) => COLLECTION_CUSTOMER_POSTPAY_CHANGE_PLAN,
    doc_customers_postpay_change_plan_name: (state) => DOC_CUSTOMER_POSTPAY_CHANGE_PLAN,
  },

  mutations: {
    SET_ACCOUNT_CUSTOMERS(state, account_customers) {
      state.account_customers = account_customers
    },

    SET_ACCOUNT_CUSTOMERS_UUIDS(state, account_customers_uuids) {
      state.account_customers_uuids = account_customers_uuids;

      dynStorage.set('acc-customers-uuids', account_customers_uuids, null, true);
    },

	FIREBASE_LOGOUT(state) {
		state.account_customers_uuids = [];
	},
  },

  actions: {
    BindAccountCustomers: firestoreAction(({ bindFirestoreRef, getters }) => {
      return bindFirestoreRef('account_customers', db.collection(`${ getters.doc_users_name }/${ getters.auth_user_uid }/${ COLLECTION_CUSTOMERS }`))
    }),

    UnbindAccountCustomers: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('account_customers')
    }),

    GetCustomersUuidsByUserUid({ getters, commit }, uid) {
      if (!uid) {
        return Promise.reject(i18n.t('no_user_id'));
      } else {
        return db
          .doc(`${getters.doc_users_name}/${uid}`)
          .get()
          .then((e) => {
            return e.ref.collection(COLLECTION_CUSTOMERS).get();
          })
          .then((e) => {
            if (e.docs.length) {
              const account_customers_uuids = e.docs.map(
                (doc) => doc.data().CustomerUUID
              );
              console.log('account_customers_uuids', account_customers_uuids)
              commit('SET_ACCOUNT_CUSTOMERS_UUIDS', account_customers_uuids);

              return Promise.resolve(account_customers_uuids);
            } else {
              commit('SET_ACCOUNT_CUSTOMERS_UUIDS', []);

              return Promise.resolve(null);
            }
          })
          .catch((error) => {
            console.error('Error getting document:', error);

            return Promise.reject(error);
          });
      }
    },

    GetUserCustomers({ dispatch }, uuids) {
      const customers_promises = uuids.map((uuid) =>
        dispatch('GetCustomerByUuid', uuid)
      );

      const customers_balances_promises = uuids.map((uuid) =>
        dispatch('GetCustomerBalanceByUuid', uuid)
      );

      return Promise.allSettled([
        ...customers_promises,
        ...customers_balances_promises,
      ]).then((results) => {
        const customers = results.slice(0, customers_promises.length);
        const balances = results
          .slice(
            customers_promises.length,
            customers_promises.length + customers_balances_promises.length
          )
          .map(({ value }) => value);

        const entries = customers.reduce((acc, { value }, i) => {
          if (value) {
            return [...acc, [uuids[i], { ...value, balance: balances[i] }]];
          } else {
            return acc;
          }
        }, []);

        return Object.fromEntries(entries);
      });
    },

    GetCustomerByUuid({ getters }, uuid) {
      if (!uuid) {
        return Promise.reject(i18n.t('no_customer_UUID'));
      } else {
        return db
          .collection(COLLECTION_CUSTOMERS)
          .doc(uuid)
          .get()
          .then((doc) => {
            return doc.ref.collection(getters.collection_customers_mobiles_name).get().then(mobile_products => {
                for (let mobile_product of mobile_products.docs) {
                    const mobile_product_data = mobile_product.data()

                    if (mobile_product_data?.Status == 4 && (mobile_product_data?.BillingReference?.startsWith('prepay') || mobile_product_data?.BillingReference?.startsWith('payg'))) {
                        return {...doc.data(), ServiceType: 'prepay'}
                    }

                    if (mobile_product_data?.Status == 4 && mobile_product_data?.BillingReference?.startsWith('postpay')) {
                        return {...doc.data(), ServiceType: 'postpay'}
                    }

                    if (mobile_product_data?.Status < 4 || !mobile_product_data?.BillingReference) {
                        return {...doc.data(), ServiceType: 'Not Activated'}
                    }
                }

                return {...doc.data(), ServiceType: 'Not Active'}
            })
          })
          .catch((error) => {
            console.error('Error getting document:', error);
            return Promise.reject(error);
          });
      }
    },

    GetCustomerBalanceByUuid({}, uuid) {
      if (!uuid) {
        return Promise.reject(i18n.t('no_customer_UUID'));
      } else {
        return db
          .collection(COLLECTION_CUSTOMERS)
          .doc(uuid)
          .get()
          .then((customer) => {
            return customer.ref
              .collection(COLLECTION_CUSTOMER_PREPAY_PRODUCTS)
              .get();
          })
          .then((prepay_products) => {
            if (prepay_products.docs?.length) {
              return prepay_products.docs[0].data();
            } else {
              return Promise.resolve(null);
            }
          })
          .then((prepay_product) => {
            let balance = 0;

            if (prepay_product?.CreditCents) {
              balance = prepay_product.CreditCents / 100;
            }

            return balance;
          })
          .catch((error) => {
            console.error('Error getting document:', error);
            return Promise.reject(error);
          });
      }
    },
  },
};
