import i18n from '@/i18n';

const i18n_month_name = i18n.t('month_name');
const i18n_week_day = i18n.t('week_day');
const i18n_day_part = i18n.t('day_part');

const padZeros = (input, maxLength) => `0000${input}`.slice(-maxLength);

const dayOfWeekNames = [
    i18n_week_day.sunday, 
    i18n_week_day.monday, 
    i18n_week_day.tuesday, 
    i18n_week_day.wednesday, 
    i18n_week_day.thursday, 
    i18n_week_day.friday, 
    i18n_week_day.saturday,
];

const dayOfWeekNamesShort = [
    i18n_week_day.short.sun, 
    i18n_week_day.short.mon, 
    i18n_week_day.short.tue, 
    i18n_week_day.short.wed, 
    i18n_week_day.short.thu, 
    i18n_week_day.short.fri, 
    i18n_week_day.short.sat, 
];

const monthNames = [
    i18n_month_name.january, 
    i18n_month_name.february, 
    i18n_month_name.march, 
    i18n_month_name.april, 
    i18n_month_name.may, 
    i18n_month_name.june, 
    i18n_month_name.july, 
    i18n_month_name.august, 
    i18n_month_name.september, 
    i18n_month_name.october, 
    i18n_month_name.november, 
    i18n_month_name.december,
];

const monthNamesShort = [
    i18n_month_name.short.jan, 
    i18n_month_name.short.feb, 
    i18n_month_name.short.mar, 
    i18n_month_name.short.apr, 
    i18n_month_name.short.may, 
    i18n_month_name.short.jun, 
    i18n_month_name.short.jul, 
    i18n_month_name.short.aug, 
    i18n_month_name.short.sep, 
    i18n_month_name.short.oct, 
    i18n_month_name.short.nov, 
    i18n_month_name.short.dec, 
];

const year =    input => input.getFullYear()
const month =   input => input.getMonth() + 1
const date =    input => input.getDate()
const hours24 = input => input.getHours()
const hours12 = input => hours24(input) % 12 === 0 ? 12 : hours24(input) % 12
const minutes = input => input.getMinutes()
const seconds = input => input.getSeconds()
const weekday = input => input.getDay()

const format_functions = {
    'YYYY':  input => padZeros(year(input), 4),
    'YY':    input => padZeros(year(input) % 100, 2),
    'MMMM':  input => monthNames[month(input) - 1],
    'MMM':   input => monthNamesShort[month(input) - 1],
    'MM':    input => padZeros(month(input), 2),
    'M':     input => `${month(input)}`,
    'DD':    input => padZeros(date(input), 2),
    'D':     input => `${date(input)}`,
    'HH':    input => padZeros(hours24(input), 2),
    'H':     input => `${hours24(input)}`,
    'hh':    input => padZeros(hours12(input), 2),
    'h':     input => `${hours12(input)}`,
    'mm':    input => padZeros(minutes(input), 2),
    'm':     input => `${minutes(input)}`,
    'ss':    input => padZeros(seconds(input), 2),
    's':     input => `${seconds(input)}`,
    'A':     input => hours24(input) < 12 ? i18n_day_part.am.toUpperCase() : i18n_day_part.pm.toUpperCase(),
    'a':     input => hours24(input) < 12 ? i18n_day_part.am : i18n_day_part.pm,
    'dddd':  input => dayOfWeekNames[weekday(input)],
    'ddd':   input => dayOfWeekNamesShort[weekday(input)],
    'd':     input => `${weekday(input)}`,
}

// formatDate expects input to be a Javascript date object
export default function formatDate(input, format) {
    if (!input) {
        return ""
    }
    if (!format) {
        format = "DD-MM-YYYY, HH:mm:ss"
    }

    const reg_exp = new RegExp(`(${Object.keys(format_functions).join('|')})`)

    let formatted_date = null
    try {
        formatted_date = format.split(reg_exp)
            .map(item => format_functions[item] ? format_functions[item](input) : item)
            .join('')
    } catch (e) {}

    return formatted_date
}