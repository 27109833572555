import store from '@/store';

export default (to, from, next) => {
    const is_sim_purchasing_allowed = store.getters.is_sim_purchasing_allowed;

    if (!is_sim_purchasing_allowed) store.commit('RESET_SIM');

    return {
        allow: is_sim_purchasing_allowed,
        redirect: { name: 'sign-up' },
    }
};
