import i18n from '@/i18n';

const ufb_plan_types_list = [
    {
        name: 'broadband',
        title: i18n.t('ufb_plan_types_list.broadband.title'),
        dropdown_title: i18n.t('ufb_plan_types_list.broadband.dropdown_title'),
        lead: i18n.t('ufb_plan_types_list.broadband.lead'),
        features: [
            i18n.t('ufb_plan_types_list.broadband.features.great_data_packages'),
            i18n.t('ufb_plan_types_list.broadband.features.bring_existing_number'),
            i18n.t('ufb_plan_types_list.broadband.features.amazing_loyalty_deals'),
            i18n.t('ufb_plan_types_list.broadband.features.solid_network'),
        ]
    }
]

export default ufb_plan_types_list
