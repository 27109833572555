import i18n from '@/i18n';

/**
 * Downloads Blob
 * @param {string} file_name - The file name should be saved
 * @param {Blob} blob - The blob should be downloaded
 */

const downloadBlob = (file_name, blob) => {
    if (!file_name) return Promise.reject(new Error(i18n.t('file_name_not_specified')));
    if (!blob || !(blob instanceof Blob) || blob.size == 0) return Promise.reject(new Error(i18n.t('file_downloading_error')));

    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = file_name;

    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);
                
    downloadLink.href = downloadUrl;
    document.body.append(downloadLink);
    downloadLink.click();
                
    setTimeout(() => {
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadUrl);
    }, 100);

    return Promise.resolve(blob)
}

export default downloadBlob;