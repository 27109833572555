import { db } from '@/utils/firebase.js'
import StoreCache from '@/utils/StoreCache.js'
const DEFAULT_LIFETIME = 1 * 60 * 60 * 1000 // 1 hour

const state = {
    feedback_questions: new StoreCache(DEFAULT_LIFETIME),
}

const getters = {
    feedback_questions: (state) => state.feedback_questions.cachedData() || [],
    has_feedback_questions: (state, getters) => Boolean(getters.feedback_questions.length),
}

const actions = {
    GetFeedbackQuestions({ state, commit }, year = new Date().getFullYear() - 1) {
        const feedback_questions = state.feedback_questions

        if (!feedback_questions.expired()) {
            return Promise.resolve(feedback_questions.cachedData())
        }

        return db.collection('feedback').get().then(feedback_collection => {
            const feedback_questions = feedback_collection?.docs?.find(doc => doc.id == 'web_questions')?.data()

            const last_year_feedback_questions = feedback_questions?.[year]

            if (!last_year_feedback_questions?.length) {
                commit('RESET_FEEDBACK_QUESTIONS')

                return Promise.resolve([])
            }

            last_year_feedback_questions.sort((a, b) => a.id - b.id)

            commit('SAVE_FEEDBACK_QUESTIONS', last_year_feedback_questions)

            return last_year_feedback_questions
        }).catch(error => {
            console.log('GetFeedbackQuestions error', {error});

            commit('RESET_FEEDBACK_QUESTIONS')
    
            return Promise.resolve([])
        })
    },
}

const mutations = {
    SAVE_FEEDBACK_QUESTIONS(state, feedback_questions) {
        state.feedback_questions.fix(feedback_questions)
    },

    RESET_FEEDBACK_QUESTIONS(state) {
        state.feedback_questions.reset()
    },
}

export default {
  	state,
  	getters,
  	mutations,
  	actions,
}
