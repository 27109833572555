/**
 * Перехватчик запросов для axios:
 * - добавляет токен авторизации в заголовке Authorization при отправке каждого запроса
 * - обрабатывает полученнй результат по запросу
 * - разлогинивает юзера при получении 401
 *
 * @param {*} Vue
 * @param {*} axios
 * @param {*} store
 * @param {*} router
 */
 const axios_config = {
    timeout: 65000,
    maxRedirects: 1,
    withCredentials: false,
  };
  
const X_Auth_Key = 'firebase_identity';
  
function axiosMiddleware(Vue, axios, store, router) {
    axios.defaults.baseURL = process.env.VUE_APP_RESELLER_API_HOST

    // добавляем токен авторизации
    // add auth token
    axios.interceptors.request.use(
        async (config) => {
            if (!config.headers.common.hasOwnProperty(X_Auth_Key) && !(config.params && config.params.noauth)) {
                const auth_token = await store.dispatch('FirebaseGetUpdatedAuthToken')

                if (auth_token) {
                    config.headers.common[X_Auth_Key] = auth_token
                }
            }
            
            const uuid = store.getters.current_account_uid
            if (uuid) {
                config.url = config.url.replace(/:uuid(\/|\?|$)/gi, `${ uuid }$1`)
            }
    
            return { ...axios_config, ...config }
        },
        (error) => Promise.reject(error),
    )
  
    axios.interceptors.response.use(
        // обрабатываем результат
        // process the result
        (response) => {
            response.result = response.data
            response.apidata = response.data
    
            return Promise.resolve(response)
        },
        (error) => Promise.reject(error),
    )
}
  
export default axiosMiddleware;
  