import store from '@/store'
import AnimationsStateMachines from '@theme/animations/state-machines.json'

const state = {
    play_animation: { name: '' },
    stop_animation: { name: '' },
    AnimationsStateMachines,
}

const getters = {
    play_animation: state => state.play_animation,
    stop_animation: state => state.stop_animation,
    AnimationsStateMachines: state => state.AnimationsStateMachines,
}

const actions = {
    PlayAnimation({ getters, commit }, { animation = { name: '' }, timeout = 5000 } = {}) {
        if (!animation.name) return

        commit('PLAY_AMIMATION', animation)

        return new Promise(resolve => {
            let resolved = false

            const unwatch = store.watch((state, getters) => getters.stop_animation, stop_animation => {
                if (stop_animation.name == animation.name && !resolved) {
                    unwatch()

                    commit('PLAY_AMIMATION', { name: '' })
                    commit('STOP_AMIMATION', { name: '' })

                    resolved = true
    
                    resolve(animation)
                }
            })

            setTimeout(() => {
                if (resolved) return

                commit('PLAY_AMIMATION', { name: '' })
                commit('STOP_AMIMATION', { name: '' })

                resolved = true

                if (unwatch) {
                    unwatch()
                }
    
                resolve(animation)
            }, timeout);
        })
    },

    StopAnimation({ commit }, animation = { name: '' }) {
        commit('STOP_AMIMATION', animation)
    },
}

const mutations = {
    PLAY_AMIMATION(state, animation) {
        state.play_animation = animation
    },

    STOP_AMIMATION(state, animation) {
        state.stop_animation = animation
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}