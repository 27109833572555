import SIMS_MULTI_PLANS_SETTINGS from '@/helpers/sims-multi-plans-settings'



export const CONFIG_SIM_MULTI_PLAN_SETTINGS = {}
export const DEFAULT_SIM_MULTI_PLAN_SETTINGS = {}
export const EDITABLE_SIM_MULTI_PLAN_SETTINGS = {}

for (const plan in SIMS_MULTI_PLANS_SETTINGS) {
    CONFIG_SIM_MULTI_PLAN_SETTINGS[plan] = {}
    DEFAULT_SIM_MULTI_PLAN_SETTINGS[plan] = {}
    EDITABLE_SIM_MULTI_PLAN_SETTINGS[plan] = {}

    for (const key in SIMS_MULTI_PLANS_SETTINGS[plan]) {
        if ('value' in SIMS_MULTI_PLANS_SETTINGS[plan][key]) {
            CONFIG_SIM_MULTI_PLAN_SETTINGS[plan][key] = SIMS_MULTI_PLANS_SETTINGS[plan][key].value
        } else {
            DEFAULT_SIM_MULTI_PLAN_SETTINGS[plan][key] = SIMS_MULTI_PLANS_SETTINGS[plan][key].default
            EDITABLE_SIM_MULTI_PLAN_SETTINGS[plan][key] = SIMS_MULTI_PLANS_SETTINGS[plan][key].editable
        }
    }
}



import { AVAILABLE_SIM_TYPE_PRODUCTS } from '@/helpers/eshop'

// "@/store" еще не существует на этапе формирования этого файла,
// так как он import-ится в модуль стора "sims-multi-plans"
export const DEFAULT_SIM_TYPE = process.env['VUE_APP_DEFAULT_SIM_TYPE'] == 'e_sim'
                             && process.env['VUE_APP_ENABLE_ESIM_MENU_ITEM'] == 'true'
                                ? 'e_sim'
                                : 'real_sim'
export const DEFAULT_SIM_MULTI_PLAN_ITEM_UUID = AVAILABLE_SIM_TYPE_PRODUCTS[DEFAULT_SIM_TYPE].UUID



export const simsMultiPlansToDefaultSettings = (plans, plan, update_settings = true) => {
    const default_plan_settings = DEFAULT_SIM_MULTI_PLAN_SETTINGS[plan]
    const min_sims_available = CONFIG_SIM_MULTI_PLAN_SETTINGS[plan]?.MinSimsAvailable || 2

    if (Array.isArray(plans)) {
        const count = plans.length

        if (update_settings) {
            for (let i = 0; i < count; i++) {
                for (const key in default_plan_settings) {
                    plans[i][key] = default_plan_settings[key]
                }
            }
        } else {
            const editable_plan_settings = EDITABLE_SIM_MULTI_PLAN_SETTINGS[plan]

            for (let i = 0; i < count; i++) {
                for (const key in default_plan_settings) {
                    if (!(key in plans[i] && editable_plan_settings[key])) {
                        plans[i][key] = default_plan_settings[key]
                    }
                }
            }
        }

        if (count < min_sims_available) {
            for (let i = count; i < min_sims_available; i++) {
                plans.push({...default_plan_settings})
            }
        }
    } else {
        plans = []

        for (let i = 0; i < min_sims_available; i++) {
            plans.push({...default_plan_settings})
        }
    }

    for (let i = 0, len = plans.length; i < len; i++) {
        if (!plans[i].ItemUUID) {
            plans[i].ItemUUID = DEFAULT_SIM_MULTI_PLAN_ITEM_UUID
        }
    }

    return plans
}