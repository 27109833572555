export default {
    env_theme: {
        // PAYMENT METHODS
        payment_methods_title: 'Nga Tikanga Utu',

        // FOOTER LINKS: title => link | title => link 
        footer_links: 'Nga Ture me nga Tikanga => terms | Kaupapahere tūmataiti => privacy',
    },

    my_app_home: 'Taku Tello',
}
