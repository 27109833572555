<template>
    <transition name="app-timer-badge-fade" appear>
        <app-card class="app-timer-badge">
            <div class="app-timer-badge__container">
                <p class="app-timer-badge__title">{{$t('timer')}}:</p>
                <app-timer :seconds="seconds" :divider="divider" @secondsLeft="value => secondsLeft = value" @timerCompleted="$emit('timerCompleted')"/>
            </div>
            
            <RadialProgressBar
                class="app-timer-badge__progress--bar"
                startColor="var(--brand-color-primary)"
                stopColor="var(--brand-color-primary)"
                :totalSteps="secondsTotal"
                :completedSteps="secondsTotal - secondsLeft"
                :strokeWidth="is_mobile ? 3 : 5"
                :innerStrokeWidth="is_mobile ? 4 : 6"
                :diameter="is_mobile ? 36: 60"
                :animateSpeed="600"
                innerStrokeColor="#f2f2f2"
            />
        </app-card>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex'

import AppCard from '@/components/app-card'
import AppTimer from '@/components/app-timer'
import RadialProgressBar from 'vue-radial-progress'

export default {
    props: {
        seconds: { Type: Number, default: 60    }, // max 3600 = 1 hour
        divider: { Type: String, default: ' : ' },
    },

    components: {
        AppCard,
        AppTimer,
        RadialProgressBar,
    },

    data() {
        return {
            secondsTotal: 0,
            secondsLeft: 0,
        }
    },

    mounted() {
        setTimeout(() => {
            this.secondsTotal = this.seconds
        }, 100)
    },

    computed: {
        ...mapGetters(['is_mobile']),
    },
}
</script>

<style lang="scss">
.app-timer-badge {
    padding: 8px 8px 8px 16px;
    display: flex;
    align-items: center;

    &-fade-enter-active, &-fade-leave-active {
        transition: opacity 1s ease;
    }

    &-fade-enter, &-fade-leave-to {
        opacity: 0;
    }

    @include media-bp(mob) {
        padding: 8px;
    }

    &__container {
        display: flex;
        flex-direction: column;

        @include media-bp(mob) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.14;
        color: $text-color-base;

        @include media-bp(mob) {
            margin-right: 4px;
            font-size: 12px;
            line-height: 1.33;
        }
    }

    &__progress--bar {
        margin: -2px -3px -2px 13px;

        @include media-bp(mob) {
            margin: -2px -3px -2px 8px;
        }
    }

    .app-timer {
        &--minutes, &--seconds {
            font-size: 24px;
            font-weight: bold;

            @include media-bp(mob) {
                font-size: 20px;
                line-height: 1.6;
            }
        }

        &--divider {
            position: relative;
            top: -2.5px;
            margin: 0 1px;
            font-size: 24px;
            line-height: 1.2;

            @include media-bp(mob) {
                margin: 0;
                top: -2px;
                font-size: 20px;
            }
        }
    }
}
</style>
