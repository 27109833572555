import Vue from 'vue'

import dynStorage from '@/utils/storage-dynamic'

import { simsMultiPlansToDefaultSettings } from '@/helpers/sims-multi-plans'
import { eshopCartItemsToSimsMultiPlans_v2 } from '@/store/modules/eshop-v2'

const SIMS_MULTI_PLANS = 'sims-multi-plans'

const state = {
    sims_multi_plan: null,
    sims_multi_plans: [],

    sims_multi_cart: {},
}

const getters = {
    sims_multi_plan: state => state.sims_multi_plan,
    sims_multi_plans: state => state.sims_multi_plans,

    sims_multi_plans_limit: () => parseInt(process.env.VUE_APP_SIMS_MULTI_PLANS_LIMIT) || Number.MAX_SAFE_INTEGER,
    sims_multi_plans_member_name: () => process.env.VUE_APP_SIMS_MULTI_PLANS_MEMBER_NAME == 'true',

    is_sims_multi_plans_family_allowed: () => process.env.VUE_APP_SIMS_MULTI_PLANS_FAMILY == 'true',
    is_sims_multi_plans_solo_allowed: () => true, // всегда доступна для заказа
    is_sims_multi_plans_companion_allowed: () => process.env.VUE_APP_SIMS_MULTI_PLANS_COMPANION == 'true',
    is_sims_multi_plans_allowed: (state, getters) => getters.is_sims_multi_plans_family_allowed
                                                    || getters.is_sims_multi_plans_companion_allowed,
}

const mutations = {
    simsMultiPlansSet(state, { plan, sims }) {
        let changed = false

        if (plan && plan != state.sims_multi_plan) {
            state.sims_multi_plan = plan
            changed = true
        }

        if (sims) {
            state.sims_multi_plans = sims
            changed = true
        }

        if (changed) {
            dynStorage.set(SIMS_MULTI_PLANS, {
                plan: state.sims_multi_plan,
                sims: state.sims_multi_plans,

                cart: state.sims_multi_cart,
            }, false, true)
        }
    },

    simsMultiPlansLoad(state, plan) {
        const sims_multi_plans = dynStorage.get(SIMS_MULTI_PLANS, true)
        
        state.sims_multi_plan = sims_multi_plans?.plan
        state.sims_multi_plans = simsMultiPlansToDefaultSettings(sims_multi_plans?.sims, plan, state.sims_multi_plan != plan)
        state.sims_multi_cart = sims_multi_plans?.cart || {}
    },
    
    simsMultiPlansRestore(state, eshop_v2_cart) {
        if (eshop_v2_cart?.UUID
            && eshop_v2_cart.Flow
            && eshop_v2_cart.UpdatedAtNanos
            && (!state.sims_multi_cart?.uuid
                || !state.sims_multi_cart.updated
                || state.sims_multi_cart.uuid != eshop_v2_cart.UUID
                || state.sims_multi_cart.updated < eshop_v2_cart.UpdatedAtNanos
            )
        ) {
            state.sims_multi_plan = eshop_v2_cart.Flow

            const sims_multi_plans = eshopCartItemsToSimsMultiPlans_v2(eshop_v2_cart.Items)
            if (sims_multi_plans.length) {
                state.sims_multi_plans = sims_multi_plans
            }

            state.sims_multi_cart = {
                uuid: eshop_v2_cart.UUID,
                updated: eshop_v2_cart.UpdatedAtNanos,
            }

            dynStorage.set(SIMS_MULTI_PLANS, {
                plan: state.sims_multi_plan,
                sims: state.sims_multi_plans,

                cart: state.sims_multi_cart,
            }, false, true)
        }
    },

    simsMultiPlansReset(state) {
        state.sims_multi_plans = []

        dynStorage.remove(SIMS_MULTI_PLANS)
    },

    simMultiPlanAddon(state, { addon, index }) {
        if (index < state.sims_multi_plans.length && addon?.Availabilities?.[0]) {
            Vue.set(state.sims_multi_plans[index], 'Product', {
                ProductType: addon.ProductType,
                is_payg: addon.is_payg,
                is_autoRenew: addon.is_autoRenew,

                MobileProductAvailabilityUUID: addon.Availabilities[0]?.UUID,
                MobileProductCatalogUUID: addon.Availabilities[0]?.ProductCatalogEntryUUID,
                PriceCents: addon.Availabilities[0]?.RetailCents,
            })

            dynStorage.set(SIMS_MULTI_PLANS, {
                plan: state.sims_multi_plan,
                sims: state.sims_multi_plans,

                cart: state.sims_multi_cart,
            }, false, true)
        }
    },
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions,
}
