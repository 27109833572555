import axiosIntents from '@/utils/axios-intents'
import downloadBlob from '@/utils/downloadBlob.js'
import i18n from '@/i18n';

import { INVOICE_STATUSES, INVOICES_BILLING_CYCLES } from '@/helpers/invoices'

export default {
    state: {
        invoices: [],
        is_loading_invoices: false,
        is_downloading_invoice_pdf: false,
        invoices_loading_error: false,
        invoices_current_billing_cycle: null,
    },

    getters: {
        invoices: state =>  state.invoices,
        current_period_invoice: (state, getters) => getters.invoices.find(invoice => invoice.Status.toUpperCase() == INVOICE_STATUSES.OPEN.status),
        current_period_invoices: (state, getters) => getters.invoices.filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUSES.OPEN.status),
        previous_period_invoices: (state, getters) => state.is_loading_invoices ? [] : getters.invoices.filter(invoice => invoice.Status.toUpperCase() != INVOICE_STATUSES.OPEN.status),
        has_overdue_invoice: (state, getters) => getters.overdue_invoices.length > 0,
        due_invoices: (state, getters) => state.is_loading_invoices ? [] : getters.invoices.filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUSES.DUE.status),
        overdue_invoices: (state, getters) => state.is_loading_invoices ? [] : getters.invoices.filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUSES.OVERDUE.status),
        due_and_overdue_invoices:(state, getters) => [...getters.due_invoices, ...getters.overdue_invoices],
        is_loading_invoices: state =>  state.is_loading_invoices,
        invoices_current_billing_cycle: state =>  state.invoices_current_billing_cycle,
        is_downloading_invoice_pdf: state =>  state.is_downloading_invoice_pdf,
        invoices_loading_error: state =>  state.invoices_loading_error,
    },

    actions: {
        getInvoices({getters, commit}, { force = false } = {}) {
            if (getters.is_loading_invoices || (!force && !getters.is_app_environment_invoices_allowed)) return;

            commit('SET_INVOICES_LOADING_ERROR', false)

            const uuid = getters.current_account_uid

            if (!uuid) return Promise.reject(i18n.t('no_uuid'))

            commit('SET_IS_LOADING_INVOICES', true)

            return axiosIntents.get(`/v2/customer/${ uuid }/invoices`)
                .then(({ result: invoices }) => {
                    if (!invoices || invoices.length == 0) {
                        commit('DELETE_INVOICES')
                    } else {
                        commit('SET_INVOICES', invoices)
                    }

                    return invoices
                })
                .catch(error => {
                    console.log('getInvoices error', error)

                    commit('SET_INVOICES_LOADING_ERROR', true)
                    commit('DELETE_INVOICES')

                    return Promise.resolve([])
                })
                .finally(()=> {
                    commit('SET_IS_LOADING_INVOICES', false)
                })
        },

        getInvoicesCurrentBillingCycle({getters, commit}) {
            const CustomerUUID = getters.current_account_uid

            if (!CustomerUUID || !getters.is_app_environment_billing_cycle_allowed) {
                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                return Promise.resolve(true)
            }

            return axiosIntents.get(`/customers/${CustomerUUID}/billing-cycle`).then(({data = {}} = {})=> {
                const { BillingCycle } = data

                if (!BillingCycle || !BillingCycle.Type || !BillingCycle.Value) {
                    commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                    return Promise.resolve(true)
                }

                const invoices_current_billing_cycle = BillingCycle.Type == 'monthly'
                                                        ? INVOICES_BILLING_CYCLES.MONTHLY
                                                        : BillingCycle.Value == 7
                                                            ? INVOICES_BILLING_CYCLES.SEVEN_DAYS
                                                            : INVOICES_BILLING_CYCLES.TWENTY_EIGHT_DAYS

                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', invoices_current_billing_cycle)

                return Promise.resolve(invoices_current_billing_cycle)

            }).catch(error => {
                console.log('getInvoicesCurrentBillingCycle Error', error)

                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                return Promise.resolve(true)
            })
        },

        setInvoicesCurrentBillingCycle({getters, commit, dispatch}, payload = {}) {
            if (!getters.is_app_environment_billing_cycle_allowed) {
                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', null)

                return Promise.reject(i18n.t('not_allowed'))
            }

            const CustomerUUID = getters.current_account_uid

            if (!CustomerUUID) {
                return Promise.reject(i18n.t('no_uuid'))
            }

            return axiosIntents.post(`/customers/${CustomerUUID}/billing-cycle`, payload).then(()=> {
                const { BillingCycle } = payload

                const invoices_current_billing_cycle = BillingCycle.Type == 'monthly'
                                                        ? INVOICES_BILLING_CYCLES.MONTHLY
                                                        : BillingCycle.Value == 7
                                                            ? INVOICES_BILLING_CYCLES.SEVEN_DAYS
                                                            : INVOICES_BILLING_CYCLES.TWENTY_EIGHT_DAYS

                commit('SET_INVOICES_CURRENT_BILLING_CYCLE', invoices_current_billing_cycle)

                return Promise.resolve(invoices_current_billing_cycle)
            }).catch(error => {
                console.log('setInvoicesCurrentBillingCycle Error', error)

                return Promise.reject(error)
            })
        },

        downloadInvoicePdf({getters, commit}, invoice_number) {
            if (getters.is_downloading_invoice_pdf) return;

            const uuid = getters.current_account_uid

            if (!uuid) return Promise.reject(new Error(i18n.t('no_uuid')))
            if (!invoice_number) return Promise.reject(new Error(i18n.t('no_invoice_number')))
            
            commit('SET_IS_DOWNLOADING_INVOICE_PDF', true)

            return axiosIntents.get(`/customer/${ uuid }/invoice/${invoice_number}/pdf`, {responseType: 'blob'})
                .then(({ result: blob }) => {
                    return downloadBlob(`${invoice_number}.pdf`, blob)
                })
                .catch(error => {
                    console.log('downloadInvoicePdf error', error)

                    return Promise.reject(error)
                })
                .finally(()=> {
                    commit('SET_IS_DOWNLOADING_INVOICE_PDF', false)
                })
        },
    },

    mutations: {
        SET_IS_LOADING_INVOICES(state, is_loading_invoices) {
            state.is_loading_invoices = is_loading_invoices
        },

        SET_IS_DOWNLOADING_INVOICE_PDF(state, is_downloading_invoice_pdf) {
            state.is_downloading_invoice_pdf = is_downloading_invoice_pdf
        },

        SET_INVOICES_LOADING_ERROR(state, invoices_loading_error) {
            state.invoices_loading_error = invoices_loading_error
        },

        SET_INVOICES(state, invoices) {
            state.invoices = invoices
        },

        DELETE_INVOICES(state) {
            state.invoices = []
        },

        SET_INVOICES_CURRENT_BILLING_CYCLE(state, current_billing_cycle) {
            state.invoices_current_billing_cycle = current_billing_cycle
        },
    },
}
