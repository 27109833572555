import axios from 'axios';
import StoreCache from '@/utils/StoreCache.js'

import i18n from '@/i18n'

const MAP_PLAN_API = {
    postpay: {apiURL: '/v1/:spid/postpay_mobile_plans', responseList: 'PostpayMobilePlans', mut: 'SAVE_POSTPAY_PLANS'},
    prepay: {apiURL: '/v3/:spid/prepay_mobile_plans', responseList: 'PrepayAddons', mut: 'SAVE_PREPAY_PLANS'},
    prepay_addons: {apiURL: '/v3/:spid/prepay_mobile_plans', responseList: 'PrepayAddons', mut: 'SAVE_PREPAY_ADDONS'},
}

const DEFAULT_PLAN_LIFETIME = 1 * 60 * 1000 // 1 minute

export default {
    state: {
        postpay: new StoreCache(DEFAULT_PLAN_LIFETIME),
        prepay: new StoreCache(DEFAULT_PLAN_LIFETIME),
        prepay_addons: new StoreCache(DEFAULT_PLAN_LIFETIME),
    },

    getters: {
    },

    actions: {
        getProductsByType({state, commit}, plan_type) {
            const cachedProducts = state[plan_type]
            if (cachedProducts && !cachedProducts.expired()) {
                return Promise.resolve(cachedProducts.cachedData())
            }
            //check if api for plan_type exist
            if (!plan_type || !MAP_PLAN_API[plan_type] || !MAP_PLAN_API[plan_type].apiURL || !MAP_PLAN_API[plan_type].mut) {
                return Promise.reject(false)
            }

            const request_url = MAP_PLAN_API[plan_type].apiURL

            return axios.get(request_url).then(response => {
                if (!response.data) {
                    console.log(`getProductsByType ${plan_type} - no response data`)

                    return Promise.reject(Error(i18n.t('no_response_data')))
                } else {
                    const products_list = response.data[ MAP_PLAN_API[plan_type].responseList ]

                    if (products_list && Array.isArray(products_list)) {
                        commit(MAP_PLAN_API[plan_type].mut, response.data[ MAP_PLAN_API[plan_type].responseList ])

                        return Promise.resolve(state[plan_type].cachedData())
                    } else {
                        console.log(`getProductsByType ${plan_type} - no products list`)

                        return Promise.reject(Error(i18n.t('no_products_list')))
                    }
                }
            })
            .catch(error => {
                // Error response received from API
                if (error.response) {
                    if (error.response.status == 404 && error.response.data) {

                        const products_list = error.response.data[ MAP_PLAN_API[plan_type].responseList ]

                        if (products_list && Array.isArray(products_list)) {
                            return Promise.resolve(products_list)
                        }

                    }

                    // Server responded with a non-404 HTTP status code
                    console.log(`getProductsByType ${plan_type} ERROR Response:`, error)
                    return Promise.reject(error)

                } else if (error.request) {
                    // Request was made but no response was received
                    console.log(`getProductsByType ${plan_type} ERROR making request:`, error)
                    return Promise.reject(error)
                }
                // Something went wrong in setting up the request
                if (error) {
                    console.log(`getProductsByType ${plan_type} ERROR:`, error)
                    return Promise.reject(error)
                }
                console.log(`getProductsByType ${plan_type} UNKNOWN ERROR`)
                return Promise.reject(Error(`${i18n.t('unknown_error_in')} getProductsByType ${plan_type}`))
            })
        },
    },

    mutations: {
        SAVE_POSTPAY_PLANS(state, data) {
            // console.log('SAVE_POSTPAY_PLANS', data)
            state.postpay.fix(data)
        },
        SAVE_PREPAY_PLANS(state, data) {
            for (let i = 0; i < data.length; i++) {
                data[i].Cents = data[i].RecurringRetailPrice 
            }
            // console.log('SAVE_PREPAY_PLANS', data)
            state.prepay.fix(data)
        },
        SAVE_PREPAY_ADDONS(state, data) {
            for (let i = 0; i < data.length; i++) {
                data[i].Cents = data[i].RecurringRetailPrice 
            }
            // console.log('SAVE_PREPAY_ADDONS', data)
            state.prepay_addons.fix(data)
        },
    }
}